<template>
  <div
    id="password"
    class="card mt-4">
    <div class="card-header">
      <h5>{{ $tc('setting.password.changePassword') }}</h5>
    </div>
    <loading-icon
      v-if="loading"
      :size="'medium'"
      :position="'center'"
      class="ai-loader" />
    <div 
      :class="loading ? 'card-loading' : ''"
      class="card-body pt-0">
      <label class="form-label">{{ $tc('setting.password.oldPassword') }}</label>
      <div class="form-group">
        <b-input
          v-model="oldPassword"
          class="form-control"
          type="password"
          :placeholder="$tc('setting.password.oldPassword')"
          :class="(errorRequired) && validating ? 'red-border' : ''" />
      </div>
      <label class="form-label">{{ $tc('setting.password.newPassword') }}</label>
      <div class="form-group">
        <b-input
          v-model="newPassword"
          class="form-control"
          type="password"
          :placeholder="$tc('setting.password.newPassword')"
          :class="(errorMustBeSame || errorPasswordLength || errorLowercaseLength || errorUppercaseLength || errorContainNumber || errorSpecialCharacter) && validating ? 'red-border' : ''" />
      </div>
      <label class="form-label">{{ $tc('setting.password.confirmPassword') }}</label>
      <div class="form-group">
        <b-input
          v-model="confirmPassword"
          class="form-control"
          type="password"
          :placeholder="$tc('setting.password.confirmPassword')"
          :class="(errorMustBeSame || errorPasswordLength || errorLowercaseLength || errorUppercaseLength || errorContainNumber || errorSpecialCharacter) && validating ? 'red-border' : ''" />
      </div>
      <ul>
        <li
          v-if="errorPasswordLength && validating"
          class="text-error">
          {{ $tc('setting.password.errorPasswordLength') }}
        </li>
        <li
          v-if="errorLowercaseLength && validating"
          class="text-error">
          {{ $tc('setting.password.errorLowercaseLength') }}
        </li>
        <li
          v-if="errorUppercaseLength && validating"
          class="text-error">
          {{ $tc('setting.password.errorUppercaseLength') }}
        </li>
        <li
          v-if="errorContainNumber && validating"
          class="text-error">
          {{ $tc('setting.password.errorContainNumber') }}
        </li>
        <li
          v-if="errorSpecialCharacter && validating"
          class="text-error">
          {{ $tc('setting.password.errorSpecialCharacter') }}
        </li>
        <li
          v-if="errorMustBeSame && validating"
          class="text-error">
          {{ $tc('setting.password.errorMustBeSame') }}
        </li>
      </ul>
      <b-button
        type="button"
        variant="primary"
        class="btn bg-gradient-dark btn-sm float-end mt-6 mb-0"
        @click="save">
        {{ $tc('setting.password.updatePassword') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import store from '@/store/store';
import { changepassword } from "@/apis/UserApi";
import Vue from "vue";
import LoadingIcon from "@/views/Components/loadingIcon.vue";

export default {
    name: 'ChangePassword',
    components: {
      LoadingIcon
    },
    data() {
        return {
            confirm: false,
            validating: false,
            newPassword: '',
            confirmPassword: '',
            oldPassword: '',
            loading: false
        }
    },
    computed: {
        errorPasswordLength: function() {
        return this.newPassword.length < 8 || this.confirmPassword.length < 8
        },
        errorLowercaseLength: function() {
            return (this.newPassword == this.newPassword.toLowerCase()) || (this.confirmPassword == this.confirmPassword.toLowerCase())
        },
        errorUppercaseLength: function() {
            return (this.newPassword == this.newPassword.toUpperCase()) || (this.confirmPassword == this.confirmPassword.toUpperCase())
        },
        errorContainNumber: function() {
            return !(/[0-9]/.test(this.newPassword)) || !(/[0-9]/.test(this.confirmPassword))
        },
        errorSpecialCharacter: function() {
            return !(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.newPassword)) || !(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.confirmPassword))
        },
        errorMustBeSame: function() {
            return this.newPassword !== this.confirmPassword
        },
        errorRequired: function() {
            return this.oldPassword === ''
        }
    },
    methods: {
        isValidate() {
            this.validating = true
            this.$emit('validating')
            if (this.errorRequired || this.errorMustBeSame || this.errorPasswordLength || this.errorLowercaseLength || this.errorUppercaseLength || this.errorContainNumber || this.errorSpecialCharacter) {
                return false
            }
            return true
        },
        save() {
            if (this.isValidate()) {
                this.loading = true
                const user = store.getters['user/getCurrentUser']
                changepassword(user.username, this.oldPassword, this.newPassword).then(res => {
                  Vue.toasted.show(this.$tc('setting.password.success'), { type: 'success' })
                  this.loading = false
                }).catch(error => {
                  Vue.toasted.show(this.$tc('setting.password.wrongPassword'), { type: 'error' })
                  this.loading = false
                })
            }
        },
    }
}
</script>