<template>
  <div
    class="w-auto social-container text-center">
    <div
      v-if="count !== 0"
      class="avatar-status bg-danger">
      {{ count }}
    </div>
    <a
      class="cursor-pointer avatar avatar-lg social-avatar rounded-circle border border-secondary"
      :class="[!isActive ? 'inactif' : '', social.socialBrand]"
      @click="setActive()"
      :style="'background-position: center center;background-image: url(' + social.profileAvatar + ');background-size: cover;'">
      <img
        v-if="social.socialBrand === 'facebook'"
        class="social-icon"
        alt="facebook4"
        src="../../assets/img/fb.png">
      <img
        v-if="social.socialBrand === 'instagram'"
        class="social-icon"
        alt="facebook4"
        src="../../assets/img/ig.png">
    </a>
    <p
      class="mb-0 text-sm">
      {{ social.profileName }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'SocialRoundIcon',
  props: {
    social: {
      type: Object,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    socialImageUrl: function() {
      if (this.social.socialBrand === 'facebook') {
        return 'fb'
      }
      if (this.social.socialBrand === 'instagram') {
        return 'ig'
      }
      return '-'
    }
  },
  mounted() {
  },
  methods: {
    setActive() {
      if(!this.disabled) {
        this.$emit('is-clicked', this.social, this.isActive)
        this.$emit('filter-provider')
      }
    }
  }
}
</script>
<style lang="scss">


.social-avatar {
  &.instagram {
    border: 3px solid #fd0a89 !important;
  }
  &.facebook {
    border: 3px solid #295396 !important;
  }
  &.inactif{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  &:hover{
    color: white;
  }
  .social-icon {
    position: absolute;
    bottom: -12px;
    right: -12px;
    width: 65%;
    border: 3px solid white;
  }
}
.social-container {
  margin: 1rem;
  &:last-child {
    margin-right: 2rem;
  }
}
</style>