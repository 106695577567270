<template>
  <div>
    <!-- Modal -->
    <b-modal
      v-model="modalVisible"
      :title="$tc('comment.template.title')">
      <!-- List of labels -->
      <div v-if="!showForm">
        <div
          v-for="(template, index) in templates"
          :key="index"
          class="template-row"
          :class="{ active: selectedTemplate && selectedTemplate._id === template._id }"
          @click="selectedTemplate = template">
          <div class="d-flex">
            <div>
              <h6> {{ template.title }}</h6>
            </div>
            <div class="ms-auto">
              <div class="dropdown">
                <button
                  class="btn btn-link text-secondary m-0 p-0 remove-button"
                  @click="deleteTemplate(index)">
                  <i
                    class="fas fa-trash-alt remove-btn" />
                </button>
              </div>
            </div>
          </div>
          <p class="text-sm">
            {{ template.comment }}
          </p>
          <hr class="horizontal dark">
        </div>
        <b-button
          class="add-button"
          :style="'font-size: 20px;'"
          @click="showForm = true">
          <i class="fas fa-plus-square" />
        </b-button>
      </div>
      <div v-if="showForm">
        <b-form-group>
          <b-form-input
            v-model="title"
            :placeholder="$tc('comment.template.placeholder_title')" />
        </b-form-group>
        <b-form-group>
          <b-form-textarea
            v-model="message"
            :placeholder="$tc('comment.template.placeholder_message')"
            rows="1"
            no-resize
            class="form-control" />
        </b-form-group>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="showForm"
            variant="primary"
            @click="showForm = false">
            {{ $tc('comment.template.cancel') }}
          </b-button>
          <b-button
            v-if="showForm"
            variant="primary"
            class="float-end"
            :disabled="title === '' || message === ''"
            @click="createTemplate">
            {{ $tc('comment.template.create') }}
          </b-button>
          <b-button
            v-if="!showForm"
            class="float-end"
            variant="primary"
            :disabled="selectedTemplate === null"
            @click="useTemplate">
            {{ $tc('comment.template.use') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {createTemplate, getTemplates, deleteTemplate} from "@/apis/TemplateApi";

import Vue from "vue";
export default {
  name: 'templateModal',
  data() {
    return {
      selectedTemplate: null,
      modalVisible: false,
      showForm: false,
      title: '',
      templates: [],
      message: ''
    };
  },
  mounted() {
    getTemplates().then(response => {
      this.templates = response.data.templates
    })
      .catch((error) => {
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
      });
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },
    deleteTemplate(index) {
      deleteTemplate(this.templates[index]._id).then(response => {
        this.templates.splice(index, 1);
      })
        .catch((error) => {
          console.log(error);
          Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
        });
    },
    createTemplate() {
      if (this.title && this.message) {
        createTemplate(this.title, this.message).then(response => {
          this.templates.push(response.data.createdTemplate);
          this.title = '';
          this.message = '';
          this.showForm = false;
        })
          .catch((error) => {
            console.log(error);
            Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
          });
        this.newLabelText = '';
        this.selectedColor = '';
        this.showForm = false;
      }
    },
    useTemplate() {
      this.$emit('use-template', this.selectedTemplate);
      this.selectedTemplate = null;
      this.modalVisible = false;
    }
  },
};
</script>

<style lang="scss">

.remove-button {
  cursor: pointer;
}

.add-button {
  font-size: 25px;
  margin-top: 10px;
  padding: 5px 12px;
  cursor: pointer;
  background: transparent !important;
  color: #344767 !important;
}

.show-labels-modal{
  padding: 10px 4px 0px 4px !important;
  svg {
    font-size: 24px;
  }
}
.template-row{
  border-radius: 15px;
  padding: 0px 10px;
  cursor: pointer;
  svg{
    color: #ff6b6c;
  }
  &.active {
    background-image: linear-gradient(310deg, #7928CA 0%, #FF0080 100%);
    h6,p {
      color: white !important;
    }
  }
}
</style>
