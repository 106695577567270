// @ts-nocheck
import axios from 'axios'
import store from '@/store/store'
import Vue from "vue";
import { getInstance } from '@/auth/index';



const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    //baseURL: 'http://localhost:3000/web/openai',
    timeout: 90000,
    headers: {
    'Content-Type': 'application/json'
    }
})

instance.interceptors.request.use(async function (config) {
    return new Promise((resolve, reject) => {
        const checkAuthService = setInterval(async () => {
            const authService = getInstance();
            if (authService !== null && authService.auth0Client !== null) {
                const token = await authService.getTokenSilently();
                clearInterval(checkAuthService);
                config.headers.Authorization = `Bearer ${token}`
                config.headers.Usersub = authService.user.sub
                config.headers.usernickname = authService.user.nickname
                config.headers.useremail = authService.user.email
                resolve(config);
            }
        }, 2000);
    });
})

export default {
  ...instance
}