import network from './Network'

export async function changepassword(username, oldPassword, newPassword) {
  let data = JSON.stringify({
    username: username,
    oldPassword: oldPassword,
    newPassword: newPassword
  })
  return network.post('/user/changepassword', data)
      .then((response) => {
        return response.data
      })
}
