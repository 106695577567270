<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl fixed-start">
    <div class="sidenav-header">
      <a
        class="navbar-brand m-0"
        href=""
        target="_blank">
        <img
          src="../../assets/img/logo2.png"
          class="navbar-brand-img h-100"
          alt="main_logo">
      </a>
    </div>
    <hr class="horizontal dark mt-0 hide-mobile">
    <div
      id="sidenav-collapse-main"
      class="collapse navbar-collapse  w-auto ">
      <ul class="navbar-nav">
        <li
          v-if="navigationToShow.includes('Analytics')"
          class="nav-item">
          <sidebar-nav-link
            :name="$tc('menu.dashboard')"
            route-name="Analytics"
            :active="$route.name === 'Analytics'"
            :class-list="''"
            icon="fas fa-home" />
        </li>
        <li class="nav-item hide-mobile">
          <h6 class="mt-3 ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
            Gestion de postes
          </h6>
        </li>
        <li
          v-if="navigationToShow.includes('Calendar')"
          class="nav-item hide-mobile">
          <sidebar-nav-link
            :name="$tc('menu.calendar')"
            route-name="Calendar"
            :active="$route.name === 'Calendar'"
            :class-list="''"
            icon="fas fa-calendar-alt" />
        </li>
        <li
          v-if="navigationToShow.includes('composePage')"
          class="nav-item">
          <sidebar-nav-link
            :name="$tc('menu.compose')"
            route-name="composePage"
            :active="$route.name === 'composePage'"
            :class-list="''"
            icon="fas fa-bullhorn" />
        </li>
        <li
          v-if="navigationToShow.includes('engagements')"
          class="nav-item">
          <sidebar-nav-link
            :name="$tc('menu.engagements')"
            route-name="engagements"
            :active="$route.name === 'engagements'"
            :class-list="''"
            icon="fas fa-comments" />
        </li>
        <li
          v-if="navigationToShow.includes('SocialList')"
          class="nav-item hide-mobile">
          <sidebar-nav-link
            :name="$tc('menu.socials')"
            route-name="SocialList"
            :active="$route.name === 'SocialList'"
            :class-list="''"
            icon="fas fa-hashtag" />
        </li>
        <li
          v-if="navigationToShow.includes('Setting')"
          class="nav-item hide-mobile">
          <h6 class="mt-3 ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6 hide-mobile">
            paramètres
          </h6>
          <sidebar-nav-link
            :name="$tc('menu.settings')"
            route-name="Settings"
            :active="$route.name === 'Settings'"
            :class-list="''"
            icon="fas fa-cogs" />
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import SidebarNavLink from "@/views/Components/SidebarNavLink.vue";
library.add(faImages, faSmile, faCommentAlt)

export default {
  name: 'SidebarNav',
  components: {
    SidebarNavLink
  },
  mixins: [],
  data() {
    return {
      navigationToShow: []
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {
    if (import.meta.env.VITE_MODE === 'facebook') {
      this.navigationToShow = ['Calendar', 'SocialList', 'Setting']
    } else {
      this.navigationToShow = ['Analytics','Calendar', 'composePage', 'engagements', 'SocialList', 'Setting']
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
#sidenav-collapse-main {
  height: calc(100vh - 160px);
}

@media only screen and (max-width: 768px) {
  #sidenav-main {
    top: auto !important;
    height: 52px;
    bottom: 0px !important;
    transform: none;
    width: 100vw !important;
    max-width: 100vw !important;
    .sidenav-header {
      display: none;
    }
    .navbar-nav {
      width : 100%;
      display: inline-block;
      li {
        width: 33%;
        display: inline-block;
        background: white;
        margin: 0;
        .nav-link {
          display: block;
          padding: 10px 0px;
          text-align: center;
          .icon-shape{
            margin-left: calc(50% - 16px);
          }
          span {
            display: none;
          }
        }
      }
    }
    li h6 {
      display: none;
    }
    .hide-mobile {
      display: none !important;
    }
  }
}
</style>
