<template>
    <section>
        <div class="footer pt-3">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-lg-between">
                    <div class="col-lg-6 mb-lg-0 mb-4">
                        <div class="copyright text-center text-sm text-muted text-lg-start">
                            © 2023,
                            made with <i class="fa fa-heart" aria-hidden="true"></i>.
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
                            <li class="nav-item">
                                <a href="https://monfeed.com/policy-en/" class="nav-link text-muted"
                                    target="_blank">Privacy Policy</a>
                            </li>
                            <li class="nav-item">
                                <a href="https://monfeed.com/terms-and-conditions" class="nav-link pe-0 text-muted"
                                    target="_blank">Terms and Conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'FooterGeneral',
    components: {
    },
    props: {
    },
    computed: {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
    }
}
</script>
<style lang="scss"></style>