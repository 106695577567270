<template>
  <div
    class="loader"
    :class="size + ' ' + position + ' ' + margin">
    <div
      class="inner one"
      :class="size" />
    <div
      class="inner two"
      :class="size" />
    <div
      class="inner three"
      :class="size" />
  </div>
</template>

<script>

export default {
  name: 'LoadingIcon',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    position: {
        type: String,
        default: ''
    },
    margin: {
        type: String,
        default: 'l-top'
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {

  },
  methods: {
  }
}
</script>

<style lang="scss">

.loader {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
  &.medium {
    width: 164px;
    height: 164px;
  }
  &.center {
    margin: auto;
  }
  &.l-top {
    margin-top: 10rem;
  }
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #2D7DD2;
  &.medium {
    border-bottom: 10px solid #2D7DD2 !important;
  }
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #2D7DD2;
  &.medium {
    border-right: 10px solid #2D7DD2 !important;
  }
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #2D7DD2;
  &.medium {
    border-top: 10px solid #2D7DD2 !important;
  }
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
</style>
