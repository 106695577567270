export default {
    namespaced: true,
    state: {
        expiresIn: 0,
        currentUser: null,
        logoUrl: null
    },
    getters: {
        getCurrentUser: (state: { currentUser: any }) => {
            return state.currentUser
        },
        isAuthenticated: (state: { currentUser: null }) => {
            return state.currentUser != null
        },
        getLogoUrl: (state: { logoUrl: any }) => {
            return state.logoUrl
        }
    },
    mutations: {
        setCurrentUser: (state: { currentUser: any }, value: any) => {
            state.currentUser = value
        },
        setLogoUrl: (state: { logoUrl: any }, value: any) => {
            state.logoUrl = value
        }
    },
    actions: {
        changeCurrentUser: ({ commit }: any, value: any) => {
            commit('setCurrentUser', value)
        },
        logout: ({ commit }: any) => {
            commit('setAccessToken', null)
            commit('setCurrentUser', null)
        },
        setLogoUrl({ commit }: any, value: any) {
            commit('setLogoUrl', value)
        }
    }
}
