<template>
  <section>
    <div class="row">
      <div class="card-body d-flex">
        <template v-for="socialProvider in socialProviders">
          <social-round-icon
            :key="socialProvider.profileId"
            :ref="socialProvider._id"
            :social="socialProvider"
            :is-active="socialsSelected.map(x => x._id).includes(socialProvider._id)"
            @is-clicked="changeSelectedSocial" />
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-10" />
      <div class="col-2">
        <div class="container_toggle mb-2">
          <button
            id="weekBtn"
            class="toggle-btn"
            :class="activeView === 'week' ? 'active' : ''"
            @click="activeView = 'week'">
            {{ $t('calendar.week') }}
          </button>
          <button
            id="monthBtn"
            :ref="'globalCal'"
            class="toggle-btn"
            :class="activeView === 'month' ? 'active' : ''"
            @click="activeView = 'month'">
            {{ $t('calendar.month') }}
          </button>
        </div>
      </div>
    </div>
    <loading-icon
        v-if="loading"
        :size="'medium'"
        :position="'center'"
        class="ai-loader" />
    <vue-cal
      v-else
      id="globalCal"
      hide-view-selector
      :active-view="activeView"
      locale="fr"
      :events="filtredEvents"
      :show-week-numbers="false"
      events-on-month-view="true"
      :events-count-on-year-view="true"
      :deletable="false"
      :resizable="false"
      :dblclick-to-navigate="false"
      :cell-click-hold="true"
      :disable-views="['year', 'years', 'days']"
      :show-all-day-events="false"
      :on-event-click="eventClicked"
      @view-change="viewChange($event)">
      <template #event="{ event }">
        <div
          class="vuecal__event-title">
          <div>
            <img
              v-if="event.platforms.includes('facebook')"
              class="event_icons me-1"
              src="../../assets/img/fb.png">
            <img
              v-if="event.platforms.includes('instagram')"
              class="event_icons me-1"
              src="../../assets/img/ig.png">
            <span class="">
              {{ event.title }}
            </span>
          </div>
        </div>
        <div class="vuecal__event-time" />
      </template>
    </vue-cal>
    <post-detail-modal
      v-if="showPostDetailModal && currentPost && currentProvider"
      ref="postDetail"
      :key="currentPost._id"
      :post="currentPost"
      :provider="currentProvider"
      @hide="hidePostDetailModal"
      @refresh="getCalendarFeed" />
  </section>
</template>
<script>
import VueCal from 'vue-cal'
// import 'vue-cal/dist/i18n/fr.js'
import 'vue-cal/dist/vuecal.css'
import { getFeed, getSocialProviders } from "@/apis/FacebookApi";
import PostDetailModal from "@/views/Calendar/PostDetailModal.vue";
import SocialRoundIcon from "@/views/ComposeFeed/SocialRoundIcon.vue";
import Vue from "vue";
import LoadingIcon from "@/views/Components/loadingIcon.vue";
export default {
  name:"CalendarOverview",
  components: {
    LoadingIcon,
    SocialRoundIcon,
    PostDetailModal,
    VueCal
  },
  data() {
    return {
      events: [],
      posts: [],
      socialProviders: [],
      activeView: 'month',
      showPostDetailModal: false,
      currentPost: null,
      currentProvider: null,
      socialsSelected: [],
      loading: true
    }
  },
  computed: {
    filtredEvents: function() {
      return this.events.filter((event) => {
        return event.platformIds.filter(x => this.socialsSelected.map(x => x.profileId).includes(x)).length > 0
      })
    }
  },
  async mounted() {
    this.socialProviders = this.$store.getters['socialProvider/getSocialProviders']
    this.getCalendarFeed()
  },
  methods: {
    async getCalendarFeed() {
      this.events = []
      await getFeed().then((response) => {
        this.posts = response.data?.posts
        this.loading = false
      })
      .catch((error) => {
        console.log(error);
        this.loading = false
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
      });
      if (this.posts.length > 0) {
        this.posts.forEach(post => {
          let start = null
          let timeStampEnd = null
          let end = null
          if (post.scheduled) {
            start = new Date(post.scheduledAt)
            timeStampEnd = new Date(post.scheduledAt).getTime()
            end = new Date(timeStampEnd + 3600)
          } else {
            start = new Date(post.publishedAt)
            timeStampEnd = new Date(post.publishedAt).getTime()
            end = new Date(timeStampEnd + 3600)
          }
          const message = post.message
          if (post.message?.length > 20) {
            post.message = post.message.slice(0, 20) + '...'
          }
          const provider = this.socialProviders.filter(provider => post.platformIds.includes(provider.profileId))
          const event = {
            start: start,
            end: end,
            title: message,
            platforms: provider.map(x => x.socialBrand) || [],
            status: post.status,
            postId: post._id,
            platformIds: post.platformIds
          }
          this.events.push(event)
        })
      }
    },
    changeSelectedSocial(social, isActive) {
      if (!this.socialsSelected.map(x => x._id).includes(social._id)) {
        this.socialsSelected.push(social);
      } else {
        this.socialsSelected = this.socialsSelected.filter(s => s._id !== social._id);
      }
    },
    hidePostDetailModal() {
      this.currentPost = null
      this.currentProvider = null
      this.showPostDetailModal = false
    },
    eventClicked(event) {
      this.currentPost = this.posts.find(post => post._id === event.postId)
      this.currentProvider = this.socialProviders.find(x => event.platformIds.includes(x.profileId))
      this.showPostDetailModal = true
      this.$nextTick(() => {
        this.$refs.postDetail.show()
      })
    },
    viewChange(e) {
      if (e?.view) {
        this.activeView = e.view
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/soft-ui-dashboard/variables.scss";

:deep(.vuecal) {
  height: calc(100vh - 160px);
  background: $white;
  border-radius: 10px;
  .vuecal__event--all-day{
    min-height: 26px;
  }
  .vuecal__bg{
    overflow-x: hidden;
  }
  .vuecal__all-day {
    padding-right: 0;
  }
  .vuecal__header {
    font-size: 0.8rem !important;
    span{
      font-size: 0.875rem !important;
    }
  }

  .vuecal__title-bar {
    background-color: $primary !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .vuecal__title {
    background-color: $primary !important;
    span{
      -webkit-appearance: inherit !important;
      font-size: 1em !important;
    }
    button {
      box-shadow: none;
    }
  }

  .vuecal__title, .vuecal__title button, .vuecal__arrow {
    color: $white !important;
    .angle{
      margin-bottom: 2px;
    }
  }

  .vuecal__cell-events-count {
    margin-top: 1px;
    background-color: $primary;
  }

  .vuecal__no-event, .weekday-label {
    @media screen and (max-width: 767px) {
      font-size: 70%;
    }
  }

  .vuecal__event {
    border: 1px solid rgb(84, 84, 84, 0.3) !important;
    background-color: transparent !important;
    cursor: pointer;
    width: calc(100% - 10px) !important;
    height: auto !important;
    border-radius: 5px;
    margin: 5px;
  }

  & .vuecal__event-title {
    text-align: left;
    font-size: .85em !important;
    padding: 3px 0 3px 3px;
    span {
      max-height: 15px;
    }
  }

  &.vuecal--month-view .vuecal__event {
    display: flex;
  }

  &.vuecal--month-view .vuecal__event-title {
    order: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //padding: 0 2px 0 2px;
  }
  &.vuecal--week-view .vuecal__event-title {
    padding-left: 3px;
  }

  &.vuecal--month-view .vuecal__event-time {
    order: 1;
    white-space: nowrap;
    display: inline;
    padding-left: 5px;
  }

  .vuecal__event-time {
    display: none;
    font-size: .85em;
  }

  .vuecal__weekdays-headings {
    color: grey;
  }

  .vuecal__menu li {
    border-bottom-color: white;
    color: white;
  }

  .vuecal__menu li.vuecal__view-btn--active {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .vuecal__title-bar {
    height: 36px;
  }

  .vuecal__title {
    color: $gray-700;
    background-color: $white;
  }

  .vuecal__cell.vuecal__cell--today, .vuecal__cell.vuecal__cell--current {
    color: $primary;
  }

  .vuecal:not(.vuecal--day-view) .vuecal__cell.vuecal__cell--selected {
    background-color: rgba(235, 255, 245, 0.4);
  }

  .vuecal__cell.vuecal__cell--selected:before {
    border-color: rgba(66, 185, 131, 0.5);
  }
  .month-view .vuecal__cell-content {
    justify-content: flex-start;
    height: 100%;
    align-items: flex-end;
  }
  // align day number to top right
  .month-view .vuecal__cell-date {
    padding: 4px;
  }
  .month-view .vuecal__no-event {
    display: none;
  }

  .event_icons {
    width: 15px;
    vertical-align: middle;
  }
}
.container_toggle {
  width: 100%;
  background-color: rgb(59, 130, 246);
  border-radius: 0.75rem;
  color: #fff;
  padding: 0;
}
.toggle-btn {
  color: #fff;
  opacity: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-box-flex: 1;
  flex-grow: 1;
  white-space: nowrap;
  text-align: center;
  transition: all 0.1s ease-in-out 0s;
  flex-basis: 0px;
  background: transparent;
  border: 0px;
  width: 50%;
}

.toggle-btn.active {
  background-color: #2D7DD2  !important;
  color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.25);
}
:deep(.modal-header) {
  border-bottom: none !important;
  padding-bottom: 0;
  h5 {
    font-size: 1.15rem;
    font-weight: 400;
    margin: 0;
  }
  .circle {
    width: 0.825rem;
    display: inline-block;
    height: 0.825rem;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.25rem;
    &.draft {
      background: grey;
    }
    &.imported {
      background: orange;
    }
    &.scheduled {
      background: dodgerblue;
    }
    &.published {
      background: lightgreen;
    }
  }
  .close, .close:hover, .close:focus {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff;
    opacity: .5 !important;
    padding: 0;
    background-color: transparent !important;
    margin: 0;
    box-shadow: none;
  }
}
:deep(.dropdown) {
  background-color: transparent !important;
}
:deep(.dropdown .dropdown-menu:before) {
  content: "" !important;
}
</style>