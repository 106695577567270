<template>
  <section>
    <div class="card card-border">
      <div class="card-header d-flex align-items-center py-3">
        <div class="d-flex align-items-center">
          <div
            class="w-32 h-32 rounded-full relative"
            style="z-index:1"
            :style="' border-radius: 50%;background-position: center center;background-image: url('+provider.profileAvatar+');background-size: cover;'">
          </div>
          <div class="mx-2">
            <a
              href="#"
              class="text-dark page-name font-weight-600 text-sm"><b>{{ provider.profileName }}</b></a>
            <small class="d-block time-post text-muted">3 days ago <span class="dot"> • </span> <span><svg
              fill="currentColor"
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              title="Shared with Public"><title>Shared with Public</title><g
                fill-rule="evenodd"
                transform="translate(-448 -544)"><g><path
                  d="M109.5 408.5c0 3.23-2.04 5.983-4.903 7.036l.07-.036c1.167-1 1.814-2.967 2-3.834.214-1 .303-1.3-.5-1.96-.31-.253-.677-.196-1.04-.476-.246-.19-.356-.59-.606-.73-.594-.337-1.107.11-1.954.223a2.666 2.666 0 0 1-1.15-.123c-.007 0-.007 0-.013-.004l-.083-.03c-.164-.082-.077-.206.006-.36h-.006c.086-.17.086-.376-.05-.529-.19-.214-.54-.214-.804-.224-.106-.003-.21 0-.313.004l-.003-.004c-.04 0-.084.004-.124.004h-.037c-.323.007-.666-.034-.893-.314-.263-.353-.29-.733.097-1.09.28-.26.863-.8 1.807-.22.603.37 1.166.667 1.666.5.33-.11.48-.303.094-.87a1.128 1.128 0 0 1-.214-.73c.067-.776.687-.84 1.164-1.2.466-.356.68-.943.546-1.457-.106-.413-.51-.873-1.28-1.01a7.49 7.49 0 0 1 6.524 7.434"
                  transform="translate(354 143.5)" /><path
                    d="M104.107 415.696A7.498 7.498 0 0 1 94.5 408.5a7.48 7.48 0 0 1 3.407-6.283 5.474 5.474 0 0 0-1.653 2.334c-.753 2.217-.217 4.075 2.29 4.075.833 0 1.4.561 1.333 2.375-.013.403.52 1.78 2.45 1.89.7.04 1.184 1.053 1.33 1.74.06.29.127.65.257.97a.174.174 0 0 0 .193.096"
                    transform="translate(354 143.5)" /><path
                      fill-rule="nonzero"
                      d="M110 408.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-1 0a7 7 0 1 0-14 0 7 7 0 0 0 14 0z"
                      transform="translate(354 143.5)" /></g></g></svg></span></small>
          </div>
        </div>
      </div>
      <p
        v-if="post.message"
        class="mb-0 description-post">{{ post.message }}</p>
      <div
        v-if="mediaList.length === 1"
        class="containerImages single d-flex">
        <template>
          <div
            v-if="mediaList[0].mimeType.includes('video') && post.status !== 'imported'"
            class="flicking-panel-video text-center">
            <b-embed
              type="video">
              <source
                :src="mediaList[0].url"
                type="video/webm">
              <source
                :src="mediaList[0].url"
                type="video/mp4">
            </b-embed>
          </div>
          <div
            v-else
            :style="'background-image: url(' + mediaList[0].url + ');'"
            class="shadow-lg images onlyOneImg" />
        </template>
      </div>
      <div
        v-else-if="mediaList.length === 2"
        class="containerImages onlyTwo d-flex">
        <template
          v-for="(media, index) in mediaList">
          <div
            :key="'img_' + index"
            :style="'width: 100%;background-size: cover;background-image: url(' + media.url + ');'"
            class="shadow-lg images TwoImg"
            :class="'imageFb_' + index + ''" />
        </template>
      </div>
      <div
        v-else-if="mediaList.length === 3"
        class="containerImages onlyThree d-flex">
        <template
          v-for="(media, index) in mediaList">
          <div
              v-if="index === 0"
              :key="'img_' + index"
              :style="'background-image: url(' + mediaList[0].url + ');'"
              class="shadow-lg images principalImg" />
          <div
            v-else
            :key="'img_' + index"
            :style="'background-size: cover;background-image: url(' + media.url + ');'"
            class="shadow-lg images TwoImg"
            :class="'imageFb_' + index + ''" />
        </template>
      </div>
      <div
          v-else-if="mediaList.length === 4"
          class="containerImages onlyFour d-flex">
          <template
            v-for="(media, index) in mediaList">
            <div
              :key="'img_' + index"
              :style="'background-size: cover;background-image: url(' + media.url + ');'"
              class="shadow-lg images TwoImg"
              :class="'imageFb_' + index + ''" />
          </template>
        </div>
      <div
        v-else-if="mediaList.length > 3"
        class="containerImages moreThanFour d-flex">
        <template
          v-for="(media, index) in mediaList">
          <div
            v-if="index === 0"
            :key="'img_' + index"
            :style="'background-image: url(' + mediaList[0].url + ');'"
            class="shadow-lg images principalImg" />
          <div
            v-else-if="index < 4"
            :key="'img_' + index"
            :style="'background-image: url(' + media.url + ');'"
            class="shadow-lg images"
            :class="mediaList.length >= 5 ? 'row3Img' : 'row2Img'">
            <div
              v-if="mediaList.length >= 5 && index === 3"
              class="moraImages">
              <span
                class="textMoreImages">
                {{ '+' + (mediaList.length - 4) }}
              </span>
            </div>
          </div>
        </template>
      </div>
      <div class="row align-items-center px-2 mt-3 mb-3">
        <div class="col-sm-4 col-4 d-flex align-items-center text-center justify-content-center action-style">
          <div class="d-flex position-relative flex-shrink-0">
            <i
              data-visualcompletion="css-img"
              class="fb-icon"
              style="background-image: url('https://static.xx.fbcdn.net/rsrc.php/v3/yG/r/yNah6E5JwcF.png?_nc_eui2=AeFJuZMWQ4n3r2SvO5yNQEDJ3I-nw8JclIDcj6fDwlyUgC0RNPVSdzcbfDjFQHmfalY'); background-position: 0px -268px; background-size: auto; width: 18px; height: 18px; background-repeat: no-repeat; display: inline-block;" />
          </div>
          <div class="d-flex position-relative flex-shrink-0">
            <span class="me-3 action-style">Like</span>
          </div>
        </div>
        <div class="col-sm-4 col-4 d-flex align-items-center text-center justify-content-center action-style">
          <div class="d-flex position-relative flex-shrink-0">
            <i
              data-visualcompletion="css-img"
              class="fb-icon"
              style="background-image: url('https://static.xx.fbcdn.net/rsrc.php/v3/yG/r/yNah6E5JwcF.png?_nc_eui2=AeFJuZMWQ4n3r2SvO5yNQEDJ3I-nw8JclIDcj6fDwlyUgC0RNPVSdzcbfDjFQHmfalY'); background-position: 0px -230px; background-size: auto; width: 18px; height: 18px; background-repeat: no-repeat; display: inline-block;" />
          </div>
          <div class="d-flex position-relative flex-shrink-0">
            <span class="me-3 action-style">Comment</span>
          </div>
        </div>
        <div class="col-sm-4 col-4 d-flex align-items-center text-center justify-content-center action-style">
          <div class="d-flex position-relative flex-shrink-0">
            <i
              data-visualcompletion="css-img"
              class="fb-icon"
              style="background-image: url('https://static.xx.fbcdn.net/rsrc.php/v3/yG/r/yNah6E5JwcF.png?_nc_eui2=AeFJuZMWQ4n3r2SvO5yNQEDJ3I-nw8JclIDcj6fDwlyUgC0RNPVSdzcbfDjFQHmfalY'); background-position: 0px -287px; background-size: auto; width: 18px; height: 18px; background-repeat: no-repeat; display: inline-block;" />
          </div>
          <div class="d-flex position-relative flex-shrink-0">
            <span class="me-2 action-style">Share</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
    name: 'FacebookPreview',
    components: {
    },
    props: {
      post: {
        type: Object,
        required: true
      },
      provider: {
        type: Object,
        required: true
      }
    },
    computed: {
      mediaList: function() {
        return this.post.medias.map(x => this.isValidJSON(x) ? JSON.parse(x) : x)
      }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
      isValidJSON (obj) {
        try {
          JSON.parse(obj);
          return true;
        } catch (e) {
          return false;
        }
      }
    }
}
</script>
<style lang="scss">
.fb-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.dot {
  font-weight: 400;
  line-height: 1.2308;
}
.time-post {
  font-size: .8125rem;
  line-height: initial;
}
.page-name:hover {
  background-color: transparent;
  text-decoration: underline;
}
.description-post {
  padding: 4px 16px 16px 16px;
  font-size: .9375rem;
  color: #050505;
  line-height: 1.3333;
  white-space: break-spaces;
  font-family: "Segoe UI Historic" !important;
}
.action-style {
  font-size: .9375rem;
  color: #65676B;
  line-height: 1.3333;
  font-family: "Segoe UI Historic" !important;
}
.fb-icon{
  filter:  invert(39%) sepia(21%) saturate(200%) saturate(109.5%) hue-rotate(174deg) brightness(94%) contrast(86%);;
  color: #1c1e21;
  margin-right: 4px;
  margin-lefti: 4px;
}
.card-border {
  border: 1px solid #d2d6da !important;
  border-radius: 5px !important;
}
.images {
  margin: 1px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.containerImages {
  &.single, &.onlyThree, &.onlyFour, &.moreThanFour{
    flex-wrap: wrap;
  }
}
.onlyOneImg {
  min-height: 500px;
  width: 100%;
}
.principalImg {
  min-height: 220px;
  width: 100%;
}
.TwoImg {
  width: calc(50% - 2px);
  min-height: 220px;
  display: inline-block;
}
.row2Img {
  width: calc(50% - 2px);
  min-height: 210px;
  display: inline-block;
}
.row3Img {
  width: calc(33.33% - 2px);
  min-height: 140px;
  display: inline-block;
}
.moraImages {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.textMoreImages {
  position: absolute;
  color: white;
  font-size: 1.75rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>