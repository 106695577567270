<template>
  <b-modal
    ref="uploadImagesModal"
    :title="$tc('compose.image_upload.title')"
    hide-footer>
    <b-tabs
      content-class="mt-3"
      class="custom-tabs">
      <b-tab
        :title="$tc('compose.image_upload.upload')"
        active>
        <upload-medias
          ref="upload-medias"
          :accept-video="true"
          :max="1"
          :upload-msg="$tc('compose.image_upload.upload_msg')"
          :file-error="$tc('compose.image_upload.error_format')"
          :max-error="$tc('compose.image_upload.error_max')"
          class="uploadData"
          @changed="fileUploaded" />
      </b-tab>
      <!--<b-tab :title="$tc('compose.image_upload.stock')">
        <unsplash-view
          @photoSelected="imageSelected" />
      </b-tab>-->
    </b-tabs>
  </b-modal>
</template>

<script>
import UploadMedias from "vue-upload-drop-medias"
import UnsplashView from "@/views/ComposeFeed/UnsplashView.vue";
import Vue from "vue";

export default {
  name: 'UploadImages',
  components: {
    UnsplashView,
    UploadMedias
  },
  mixins: [],
  props: {
    medias: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      file: null
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    show() {
      this.$refs.uploadImagesModal.show()
    },
    hide() {
      this.$refs.uploadImagesModal.hide()
    },
    fileUploaded(file) {
      if (this.medias.length > 0 && (this.medias.some(x=> x.mimeType.includes('video') || file[0].type.includes('video')))) {
        Vue.toasted.show(this.$t('compose.errors.video_once_error'), { type: 'error' })
        this.hide()
        return
      }
      if (file[0].type.includes('video')) {
        if (this.medias.length > 0) {
          Vue.toasted.show(this.$t('compose.errors.video_once_error'), { type: 'error' })
          this.hide()
          return
        }
        if (!file[0].type.includes('mp4') && !file[0].type.includes('mov') && !file[0].type.includes('quicktime')) {
          Vue.toasted.show(this.$t('compose.errors.media_2207026'), { type: 'error' })
          this.$refs['upload-medias'].reset()
          return
        }
        if (file[0].size >= 50000000) {
          Vue.toasted.show(this.$t('compose.errors.video_too_large'), { type: 'error' })
          this.$refs['upload-medias'].reset()
          return
        }
      } else if (file[0].type.includes('image')) {
        if (file[0].size >= 5000000) {
          Vue.toasted.show(this.$t('compose.errors.media_2207004'), { type: 'error' })
          this.$refs['upload-medias'].reset()
          return
        }
      }
      this.$emit('fileUploaded', file[0])
      this.hide()
    },
    imageSelected(image) {
      this.hide()
      this.$emit('imageSelected', image)
    }
  }
}
</script>

<style lang="scss">
.modal-header {
  border: none;
  .close {
    border: none;
    outline: none;
    background: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.modal-body{
  padding-top: 0;
}
/* Styles for the tabs */
.custom-tabs {
  .nav-item a{
    border: none;
    margin: 0;
    color: #252b36;
    &.active {
      border-bottom: 2px solid #2D7DD2;
      color: #2D7DD2;
    }
  }
}
.uploadData {
  width: 100% !important;
  margin: 0 !important;
}
.clearButton, .plus {
  display: none !important;
}
.delete {
  color: #ffffff !important;
  width: 25px !important;
  height: 25px !important;
  background-color: rgba(0,0,0,0.3) !important;
}
.beforeUpload svg.icon {
  width: 100px !important;
}
</style>
