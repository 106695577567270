<template>
  <div>
    <div
      class="input-group mb-4">
      <span class="input-group-text">
        <i class="fas fa-search" />
      </span>
      <input
        v-model="searchQuery"
        class="form-control unsplash-search-input"
        :placeholder="$tc('compose.image_upload.search')"
        type="text">
    </div>
    <div
      class="photo-list">
      <div class="masonry-grid">
        <div
          v-for="photo in photos"
          :key="photo.id"
          class="photo-container">
          <img
            :src="photo.urls.thumb"
            alt="Photo">
          <div
            class="overlay">
            <div class="overlay-content">
              <p>
                {{ $tc('compose.image_upload.from_unsplash') }}
                <br>
                {{ $tc('compose.image_upload.by') }}
                <b-link
                  target="_blank"
                  :href="photo.user.links.html">
                  {{ photo.user.username }}
                </b-link>
              </p>
              <b-button
                class="m-0"
                @click="downloadPhoto(photo)">
                {{ $tc('compose.image_upload.use_image') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="load-more-container">
      <b-button
        @click="loadMorePhotos">
        {{ $tc('compose.image_upload.load_more') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name:"UnsplashView",
  data() {
    return {
      localQuery: '',
      photos: [],
      loading: false,
      page: 1
    };
  },
  computed: {
    searchQuery: {
      get() {
        return this.localQuery
      },
      set(val) {
        if (this.localQuery !== '') clearTimeout(this.timeoutId)
        this.localQuery = val
        this.timeoutId = setTimeout(() => {
          this.searchPhotos()
        }, 300)
      }
    }
  },
  methods: {
    searchPhotos() {
      this.page = 1
      this.photos = []
      this.fetchPhotos()
    },
    fetchPhotos() {
      /*
      frenchtranslate(this.localQuery).then(res => {
        axios.get(`https://api.unsplash.com/search/photos?page=${this.page}`, {
          params: {
            query: res.data.data,
            client_id: 'LF8LsqI-YSRiz92PFAyGyVhx78juAYvFYAGM-XARDTM',
          },
        })
          .then(response => {
            this.photos = [...this.photos, ...response.data.results];
            console.log(this.photos)
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      })*/
    },
    downloadPhoto(photo) {
      this.$emit('photoSelected', photo)
    },
    loadMorePhotos() {
      this.page++;
      this.fetchPhotos();
    }
  },
};
</script>

<style scoped>
.load-more-container{
     text-align: center;
}
.photo-list {
    column-count: 2;
    column-gap: 10px;
    margin-bottom: 15px;
}

.photo-container {
    break-inside: avoid;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;
}

.photo-container img {
    width: 100%;
    height: auto;
    transition: filter 0.3s ease;
}

.photo-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    .overlay-content {
        background-color: white;
        padding: 16px;
        border-radius: 6px;
    }
}

.photo-container:hover img {
    filter: brightness(50%);
}

.photo-container:hover .overlay {
    opacity: 1;
}

.overlay-content {
    text-align: center;
    a {
        font-style: italic;
        color: $link-color;
    }
}
.unsplash-search-input:focus{
  border: 1px solid #d2d6da !important;
  box-shadow: none !important;
}
</style>
