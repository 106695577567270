<template>
  <!-- End Navbar -->
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-lg-12 col-md-12">
        <div>
          <div class="card-body row d-flex">
            <Flicking
              :options="{ align: 'prev', bound: true, circular: false }"
              :plugins="plugins">
              <social-round-icon
                v-for="social in socials"
                :key="social._id"
                :ref="social._id"
                :social="social"
                :count="social._id === 'socialprovider_64bf88f9189cfd4003bf043f' ? 7 : 11"
                @is-clicked="socialClicked" />
              <span
                slot="viewport"
                class="flicking-arrow-prev is-thin" />
              <span
                slot="viewport"
                class="flicking-arrow-next is-thin" />
            </Flicking>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card blur shadow-blur max-height-vh-70 overflow-auto overflow-x-hidden mb-lg-0">
          <div class="card-header p-3 pb-0">
            <h6>
              {{ $tc('comment.title') }}
            </h6>
          </div>
          <div class="card-body p-2 overflow-auto overflow-x-hidden post-selection">
            <template
              v-for="feed in feedList">
              <a
                :key="feed._id"
                href="javascript:;"
                class="d-block p-2"
                :class="feed.active ? 'border-radius-lg bg-gradient-primary':''"
                @click="activateFeed(feed)">
                <div class="d-flex p-2">
                  <img
                    alt="Image"
                    :src="feed.img"
                    class="avatar shadow">
                  <div class="ms-3 w-100">
                    <div
                      class="avatar-status bg-danger">
                      {{ feed.comments }}
                    </div>
                    <div class="justify-content-between align-items-center">
                      <span
                        :class="feed.active ? 'text-white':''"
                        class="text-sm col-11 p-0 text-truncate d-block">
                        {{ feed.caption }}
                      </span>
                      <img
                        v-if="feed.neutral"
                        v-b-tooltip.hover
                        :title="$tc('compose.tooltip_emoji')"
                        class="sentiment-icon"
                        alt="icon-clock"
                        src="@/assets/img/casual.png">
                      <img
                        v-if="feed.positive"
                        v-b-tooltip.hover
                        :title="$tc('compose.tooltip_emoji')"
                        class="sentiment-icon"
                        alt="icon-clock"
                        src="@/assets/img/friendly.png">
                      <img
                        v-if="feed.negative"
                        v-b-tooltip.hover
                        :title="$tc('compose.tooltip_emoji')"
                        class="sentiment-icon"
                        alt="icon-clock"
                        src="@/assets/img/angry.png">
                    </div>
                  </div>
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-8">
        <div class="card blur shadow-blur max-height-vh-70">
          <div class="card-header shadow-lg">
            <div class="row">
              <div class="col-md-11">
                <div class="d-flex align-items-center">
                  <img
                    alt="Image"
                    :src="'/src/assets/imagesThemes/theme/post-2.jpg'"
                    class="avatar">
                  <div class="ms-3">
                    <h6 class="mb-0 d-block">Profiter de chaque instant.</h6>
                    <span class="text-sm text-dark opacity-8">Le 15 juin à 1:53am</span>
                  </div>
                </div>
              </div>
              <div class="col-1 my-auto ps-0">
                <div class="dropdown">
                  <button class="btn btn-icon-only shadow-none text-dark mb-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-link"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body overflow-auto overflow-x-hidden">
            <template
              v-for="comment in commentsList">
              <comment-post
                :ref="'comment_' + comment._id"
                :key="comment._id"
                :comment="comment"
                :active="comment._id === '001'"
                @open-templates="openTemplates" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <templateModal
      ref="templateModal"
      @use-template="useTemplate" />
  </div>
</template>
<script>
import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import CommentPost from "@/views/Engagement/commentPost.vue";
import templateModal from "@/views/Engagement/templateModal.vue";
import SocialRoundIcon from "@/views/ComposeFeed/SocialRoundIcon.vue";
import { getSocialProviders } from "@/apis/FacebookApi";
import Vue from "vue";
export default {
  name:"engagementDuplicateOverview",
  components: { SocialRoundIcon, CommentPost, templateModal },
  data() {
    return {
      socials: [],
      selectedComment: '',
      socialsSelected: [],
      plugins: [new Arrow()],
      feedList: [
        {
          caption: 'Chaque jour apporte de nouvelles opportunités. Il suffit d\'ouvrir les yeux.',
          _id: '001',
          img: '/src/assets/imagesThemes/theme/post-2.jpg',
          positive: false,
          neutral: true,
          negative: true,
          active: true,
          comments: 3
        },
        {
          caption: 'La vie est une aventure. Profitez de chaque moment précieux',
          _id: '002',
          img: '/src/assets/imagesThemes/theme/post-1.jpg',
          positive: true,
          neutral: false,
          negative: true,
          active: false,
          comments: 1
        },
        {
          caption: 'Chercher la beauté dans les petits moments de la vie quotidienne.',
          _id: '003',
          img: '/src/assets/imagesThemes/theme/post-3.jpg',
          positive: true,
          neutral: false,
          negative: true,
          active: false,
          comments: 4
        },
        {
          caption: 'Les meilleures choses de la vie ne sont pas des choses',
          _id: '004',
          img: '/src/assets/imagesThemes/theme/post-2.jpg',
          positive: false,
          neutral: false,
          negative: false,
          active: false,
          comments: 3
        },
      ],
      commentsList: [
        {
          message: 'Ils sont si beaux... Curieux de connaître le prix !',
          _id: '001',
          from: 'Lucie Garnier',
          url: 'https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/team-5.jpg'
        },
        {
          message: 'Je ne l\'aime pas beaucoup',
          _id: '002',
          from: 'Marie Dupont',
          url: 'https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/team-1.jpg'
        }
      ]
    }
  },
  mounted() {
    getSocialProviders().then(res => {
      this.socials = res.data.socialProviderList;
      this.$nextTick(() => {
        this.$refs[this.socials[0]._id][0].setActive(true)
      })
      this.loading = false;
    })
      .catch((error) => {
        this.loading = false;
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
  },
  methods: {
    openTemplates(commentId) {
      this.selectedComment = commentId;
      this.$refs.templateModal.showModal()
    },
    socialClicked(social, isActive) {
      if (isActive) {
        this.socialsSelected.push(social);
      } else {
        this.socialsSelected = this.socialsSelected.filter(s => s._id !== social._id);
      }
    },
    useTemplate(template) {
      this.$refs['comment_'+ this.selectedComment][0].selectTemplate(template.comment);
    },
    activateFeed(feed) {
      this.feedList.forEach(f => f.active = false);
      feed.active = true;
    }
  }
}
</script>
<style lang="scss">
.sentiment-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.flicking-arrow-prev {
  left: -20px;
  width: 40px !important;
}
.flicking-arrow-next {
  right: -20px;
  width: 40px !important;
}
.avatar-status {
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -11px;
  font-size: 15px;
  color: white;
  width: 25px;
  height: 25px;
  z-index: 2;
  padding: 2px;
  line-height: 23px;
}
.galerie {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1300px;
  margin: 70px auto 0;
  /* background: crimson; */
}
.item {
  width: 105px;
  height: 105px;
  margin: 5px;
  overflow: hidden;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.post-selection .text-truncate{
  max-width: 200px;
}
</style>
