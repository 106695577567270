<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible">
      <div class="window">
        <p class="title-card">{{ message }}</p>
        <div class="btns">
          <b-button
              type="button"
              variant="primary"
              class="btn-outline-primary  mt-4 float-end"
              @click="_cancel">
            {{ $tc('confirmationModal.cancel') }}
          </b-button>
          <b-button
              type="button"
              variant="primary"
              class="btn btn-primary  mt-4 mx-2 float-end"
              @click="_confirm">
            {{ $tc('confirmationModal.confirm') }}
          </b-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'ConfirmationModal',
  data: () => ({
    isVisible: false,
    message: undefined, // Main text content
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      this.message = opts.message
      this.isVisible = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm() {
      this.isVisible = false
      this.resolvePromise(true)
    },
    _cancel() {
        this.isVisible = false
        this.resolvePromise(false)
      }
  },
}</script>


<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  color: red;
  text-decoration: underline;
  line-height: 2.5rem;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.window {
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
</style>
