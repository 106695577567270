<template>
  <section>
    <div v-if="mediaList.length > 0 && !mediaList[0].mimeType.includes('video')" class="col-12 container-icons">
      <b-button
          v-b-tooltip.hover
          :title="$tc('compose.simpleView')"
          variant="link"
          class="text-dark pb-0 px-2"
          @click="grid = false">
          <i class="fa fa-square option-display" aria-hidden="true"></i>
        </b-button>
        
        <b-button
          v-b-tooltip.hover
          :title="$tc('compose.gridView')"
          variant="link"
          class="text-dark pb-0 px-2"
          @click="grid = true">
          <i class="fa fa-list option-display" aria-hidden="true"></i>
        </b-button>
    </div>
    <div v-if="!grid" class="card card-border">
      <div class="ms-2 d-flex align-items-center py-3 title-block">
        <div class="d-flex align-items-center">
          <a href="#">
            <div class="flex w-full h-screen items-center justify-center">
              <div
                class="w-32 h-32 rounded-full relative"
                style="z-index:1"
                :style="'border-radius: 50%;background-position: center center;background-image: url('+provider.profileAvatar+');background-size: cover;'" />
            </div>
          </a>
          <div class="mx-2">
            <a
              class="text-dark page-name font-weight-600 text-sm"
              href="#">
              <b>{{ provider.profileName }}</b>
              <small class="time-post text-muted" />
            </a>
          </div>
        </div>
      </div>
      <flicking
        v-if="mediaList.length > 0"
        :options="{ renderOnlyVisible: true, defaultIndex: 0, align: 'next' }">
        <template
          v-for="(media, index) in mediaList">
          <div
            v-if="media.mimeType.includes('video')"
            :key="index"
            class="flicking-panel-video">
            <video
              id="videoRefIg"
              ref="videoRefIg"
              style="max-width: 100%"
              :src="media.url" />
          </div>
          <img
            v-else
            :key="index"
            :src="media.url"
            class="img-preview"
            alt="preview">
        </template>
        <template
          v-if="post.medias.length > 1">
          <span
            slot="viewport"
            class="preview-arrow flicking-arrow-prev" />
          <span
            slot="viewport"
            class="preview-arrow flicking-arrow-next" />
        </template>
      </flicking>
      <div v-if="post.medias.length > 1" class="div-dots">
        <template v-for="(media, index) in post.medias">
          <div v-if="index < 3" :key="index" class="dot" />
        </template>
      </div>
      <div class="row align-items-center d-inline px-2 mt-3 mb-3">
        <div class="pe-1 post-icon d-inline">
          <svg
            aria-label="Like"
            class="x1lliihq x1n2onr6"
            color="rgb(38, 38, 38)"
            fill="rgb(38, 38, 38)"
            height="24"
            role="img"
            viewBox="0 0 24 24"
            width="24"><title>Like</title>
            <path
              d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z" />
          </svg>
        </div>
        <div class="pe-1 post-icon d-inline">
          <svg
            aria-label="Comment"
            class="x1lliihq x1n2onr6"
            color="rgb(0, 0, 0)"
            fill="rgb(0, 0, 0)"
            height="24"
            role="img"
            viewBox="0 0 24 24"
            width="24"><title>Comment</title>
            <path
              d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
              fill="none"
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </div>
        <div class="pe-1 post-icon d-inline">
          <svg
            aria-label="Partager la publication"
            class="x1lliihq x1n2onr6"
            color="rgb(0, 0, 0)"
            fill="rgb(0, 0, 0)"
            height="24"
            role="img"
            viewBox="0 0 24 24"
            width="24"><title>Partager la publication</title><line
              fill="none"
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="2"
              x1="22"
              x2="9.218"
              y1="3"
              y2="10.083" /><polygon
                fill="none"
                points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334"
                stroke="currentColor"
                stroke-linejoin="round"
                stroke-width="2" /></svg>
        </div>
        <div class="float-right d-inline save-post">
          <svg
            aria-label="Save"
            class="x1lliihq x1n2onr6"
            color="rgb(0, 0, 0)"
            fill="rgb(0, 0, 0)"
            height="24"
            role="img"
            viewBox="0 0 24 24"
            width="24"><title>Save</title>
            <polygon
              fill="none"
              points="20 21 12 13.44 4 21 4 3 20 3 20 21"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </div>
      </div>
      <div class="row align-items-center d-inline px-2">
        <p class="font-text mb-1">
          13,674 likes
        </p>
        <p
          v-if="post?.message !== ''"
          class="font-text mb-1">
          <b>{{ provider.profileName }}</b><span> {{ post.message }}</span>
        </p>
        <p
          class="date text-muted">
          <span class="date">{{ getDateValue() }}</span>
        </p>
      </div>
    </div>
    <div v-if="grid" class="grid-style">
      <section class="post-list">
        <a href="" class="post">
          <figure class="post-image" v-if="mediaList.length > 0">
            <div
              v-if="mediaList[0].mimeType.includes('video')"
              class="flicking-panel-video">
              <video
                id="videoRefIg"
                ref="videoRefIg"
                style="max-width: 100%"
                :src="mediaList[0].url" />
            </div>
            <img
              v-else
              :key="index"
              :src="mediaList[0].url"
              class="img-preview"
              alt="preview">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=10" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=1040" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=654" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=559" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=321" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=280" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=555" alt="">
          </figure>
        </a>
      
        <a href="" class="post">
          <figure class="post-image">
            <img src="https://picsum.photos/300?image=444" alt="">
          </figure>
        </a>
      </section>
    </div>
  </section>
</template>
<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTable } from '@fortawesome/free-solid-svg-icons'
library.add(faTable)

export default {
  name: 'InstagramPreview',
  props: {
    post: {
      type: Object,
      required: true
    },
    provider: {
      type: Object,
      required: true
    },
    grid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mediaList: function() {
      return this.post.medias.map(x => this.isValidJSON(x)
? JSON.parse(x)
: x)
    }
  },
  watch: {
    post: {
      handler: function (val, oldVal) {
        this.setThumbnailReel()
      },
      deep: true
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    setThumbnailReel() {
      if (this.post.thumbOffset !== 0 && this.$refs.videoRefIg) {
        this.$refs.videoRefIg[0].currentTime = this.post.thumbOffset/1000
      }
    },
    getDateValue() {
      let start = null
      if (this.post.scheduled) {
        start = new Date(this.post.scheduledAt)
      } else if(this.post.publishedAt) {
        start = new Date(this.post.publishedAt)
      } else {
        start = new Date()
      }
      const now = new Date().getTime() / 1000
      const timeStampStart = start.getTime() / 1000
      const diffTime = timeStampStart - now
      if (diffTime < 0 && (Math.abs(diffTime) / 3600) < 24) {
        return this.$t('previewPost.xHoursAgo', {hours: Math.round(Math.abs(diffTime) / 3600)})
      } else {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return start.toLocaleDateString('fr-FR', options);
      }
    },
    isValidJSON (obj) {
      try {
        JSON.parse(obj);
        return true;
      } catch (e) {
        return false;
      }
    }
  }
}
</script>
<style lang="scss">
.fb-avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}

.dot {
  font-weight: 400;
  line-height: 1.2308;
}

.time-post {
  font-size: .8125rem;
  line-height: initial;
}

.title-block {
  line-height: 18px;
}

.page-name:hover {
  background-color: transparent;
  text-decoration: none;
  color: lightgrey;
}

.action-style {
  font-size: .9375rem;
  color: #65676B;
  line-height: 1.3333;
  font-family: "Segoe UI Historic" !important;
}

.date {
  font-size: 14px;
}

.post-icon {
  width: 15px;
}

.font-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.save-post {
  position: absolute;
  right: 5px;
  width: auto !important;
}

.page-name-comment {
  font-weight: 600;
  font-size: 14px;
  color: black;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.conic-gradient:after {
  display: block;
  border-radius: 9999px;
  content: "";
  width: 112%;
  height: 112%;
  position: absolute;
  z-index: -1;
  left: -6%;
  top: -6%;
  background-image: linear-gradient(180deg, #d300c5, #ffc900);
}

.card-border {
  border: 1px solid #d2d6da !important;
  border-radius: 5px !important;
}
.flicking-panel {
  min-height: 400px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.flicking-panel-video {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  .embed-responsive-item {
    max-width: 100%;
  }
}
.preview-arrow {
  background: #f2eee6 !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  &.flicking-arrow-prev {
    left: 10px !important;
    width: 30px !important;
    &::before {
      left: 10px !important;
    } 
    &::after {
      left: 10px !important;
      top: calc(50% - 1px);
    }
  }
  &.flicking-arrow-next {
    right: 10px !important;
    width: 30px !important;
    &::before {
      right: 10px !important;
    } 
    &::after {
      right: 10px !important;
      top: calc(50% - 1px);
    }
  }
  &::before, &::after {
    background-color: #344767 !important;
    width: 10px !important;
    height: 2px !important;
  }
}
.img-preview {
  width: 100%;
  height: auto;
}
.div-dots {
  align-items: center;
  bottom: 20px;
  justify-content: center;
  pointer-events: none;
  position: relative;
  right: 0px;
  display: flex;
  z-index: 1;
  .dot {
    border-radius: 50%;
    height: 6px;
    transition: all .2s ease-in-out;
    width: 6px;
    margin: 2px;
    background-color: white;
  }
}
.grid-style{
  .post-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 293px));
  justify-content: center;
  grid-gap: 4px;
}
.post {
  cursor: pointer;
  position: relative;
  display: block;
}
.post-image {
  margin: 0;
}
.post-image img {
  width: 100%;
  vertical-align: top;
}
.post-overlay {
  background: rgba(0,0,0, .4);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}
.post:hover .post-overlay{
			display: flex;
}

.post-like,
.post-comments {
  width: 80px;
  margin: 5px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .post-list {
    grid-gap: 3px;
  }
}
}
.container-icons {
  text-align: right;
  .option-display {
    width: 25px !important;
    height: 25px;
  }
}

.description-post {
  padding: 4px 16px 16px 16px;
  font-size: .9375rem;
  color: #050505;
  line-height: 1.3333;
  white-space: break-spaces;
  font-family: "Segoe UI Historic" !important;
}
</style>