<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-md-12">
        <change-password />
        <delete-account />
      </div>
    </div>
  </div>
</template>
<script>

import DeleteAccount from "@/views/Settings/DeleteAccount.vue";
import ChangePassword from './ChangePassword.vue';

export default {
  name:"settingOverview",
  components: {
    DeleteAccount,
    ChangePassword
  },
}
</script>
<style>
</style>
