<template>
  <div class="pdf-table-card">
    <div class="header">
      <h6 class="m-0">SEARCH > ANALYSE DES MOTS CLÉS</h6>
      <h4>Niveau de qualité faible (inf. à 5)</h4>
    </div>
    <div class="sub-header">
      <h4>Niveau de qualité faible (inf. à 5)</h4>
      <h6>SEARCH > ANALYSE DES MOTS CLÉS</h6>
    </div>
    <table>
      <thead>
      <tr>
        <th style="width: 15%">Mot clé</th>
        <th style="width: 15%">Campagne</th>
        <th style="width: 15%">Groupe d'annonces</th>
        <th style="width: 6%">CPC moy.</th>
        <th style="width: 6%">Clics</th>
        <th style="width: 6%">Imp.</th>
        <th style="width: 6%">CTR</th>
        <th style="width: 6%">Coût</th>
        <th style="width: 6%">Conversions</th>
        <th style="width: 6%">Coût / Conv</th>
        <th style="width: 6%">Niveau de qualité</th>
        <th style="width: 6%">% impr. (1er pos.)</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.keyword }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.campaign }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.adset }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.cpc ? item.cpc : '-'}}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.clicks }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.impressions }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.ctr ? item.ctr : '-' }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.cost }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.conversions }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.conversions !== 0 ? item.cost / item.conversions : 0 }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.quality ? item.quality : '-' }}
          </p>
        </td>
        <td :class="index === 0 || index === items.concat(items).concat(items).length - 1 ? 'grey-row' : ''">
          <p>
            {{ item.topImpression ? item.topImpression : '-' }}
          </p>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="page-footer">
    </div>
  </div>
</template>
<script>
export default {
  name:"PdfTableCard",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getQualityClass(quality) {
      if (quality <= 3) return 'low-quality';
      if (quality <= 5) return 'medium-quality';
      if (quality > 5) return 'high-quality';
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  font-family: 'Roboto', sans-serif !important;
}
table {
  border-collapse: collapse;
  width: calc(100% - 40px);
  margin: 0 20px !important;
  th {
    height: 23px;
    border-bottom: 2px solid #dadce0;
    text-align: center;
    padding: 1px 4px;
    font-weight: 500;
    font-size: 12px;
    color: #3c4043;
    background-color: #fff;
  }
  td {
    height: 37px;
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #595d5f;
    background-color: #fff;
    p {
      margin: 0;
      font-size: 9px;
    }
    &.grey-row {
      background-color: #f8f9fa;
    }
  }
}
.page-footer {
  margin-top: 48px;
}
</style>

