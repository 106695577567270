<template>
  <!-- End Navbar -->
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-lg-12 col-md-12">
        <div>
          <div class="card-body row d-flex">
            <Flicking
              :options="{ align: 'prev', bound: true, circular: false }"
              :plugins="plugins">
              <social-round-icon
                v-for="social in socials"
                :key="social._id"
                :ref="social._id"
                :social="social"
                :is-active="social === selectedSocial"
                :count="socialCommentCount(social)"
                @is-clicked="changeSelectedSocial" />
              <span
                slot="viewport"
                class="flicking-arrow-prev is-thin" />
              <span
                slot="viewport"
                class="flicking-arrow-next is-thin" />
            </Flicking>
          </div>
        </div>
      </div>
      <loading-icon
          v-if="loading"
          :size="'medium'"
          :position="'center'"
          class="ai-loader" />
      <template v-else>
        <template v-if="commentsList.length !== 0">
          <div
              class="col-4">
            <div class="card blur shadow-blur max-height-vh-70 overflow-auto overflow-x-hidden mb-lg-0">
              <div class="card-header p-3 pb-0">
                <h6>
                  {{ $tc('comment.title') }}
                </h6>
              </div>
              <div class="card-body p-2 overflow-auto overflow-x-hidden post-selection">
                <template
                    v-for="post in filteredPostList">
                  <a
                      :key="post._id"
                      href="javascript:;"
                      class="d-block p-2"
                      :class="selectedPost && selectedPost._id === post._id ? 'border-radius-lg bg-gradient-primary':''"
                      @click="activatePost(post)">
                    <div class="d-flex p-2">
                      <img
                          v-if="post.medias.length > 0"
                          alt="Image"
                          :src="(post.medias[0]).url"
                          class="avatar shadow">
                      <img
                          v-else
                          alt="Image"
                          src="@/assets/img/text-placeholder.jpg"
                          class="avatar shadow">
                      <div class="ms-3 w-100">
                        <div
                            class="avatar-status bg-danger">
                          {{ postCommentCount(post) }}
                        </div>
                        <div class="justify-content-between align-items-center">
                      <span
                          :class="selectedPost && selectedPost._id === post._id ? 'text-white':''"
                          class="text-sm col-11 p-0 text-truncate d-block">
                        {{ post.message }}
                      </span>
                          <img
                              v-if="isNeutral(post)"
                              v-b-tooltip.hover
                              :title="$tc('compose.tooltip_emoji')"
                              class="sentiment-icon"
                              alt="icon-clock"
                              src="@/assets/img/casual.png">
                          <img
                              v-if="isPositive(post)"
                              v-b-tooltip.hover
                              :title="$tc('compose.tooltip_emoji')"
                              class="sentiment-icon"
                              alt="icon-clock"
                              src="@/assets/img/friendly.png">
                          <img
                              v-if="isNegative(post)"
                              v-b-tooltip.hover
                              :title="$tc('compose.tooltip_emoji')"
                              class="sentiment-icon"
                              alt="icon-clock"
                              src="@/assets/img/angry.png">
                        </div>
                      </div>
                    </div>
                  </a>
                </template>
              </div>
            </div>
          </div>
          <div
              v-if="selectedPost && selectedSocial"
              class="col-12 col-md-12 col-lg-8">
            <div class="card blur shadow-blur max-height-vh-70">
              <div class="card-header shadow-lg">
                <div class="row">
                  <div class="col-md-11">
                    <div class="d-flex align-items-center">
                      <img
                          v-if="selectedPost.medias.length > 0"
                          alt="Image"
                          :src="(selectedPost.medias[0]).url"
                          class="avatar">
                      <img
                          v-else
                          alt="Image"
                          src="@/assets/img/text-placeholder.jpg"
                          class="avatar">
                      <div class="ms-3">
                        <h6 class="mb-0 d-block">
                          {{ selectedPost.message }}
                        </h6>
                        <span class="text-sm text-dark opacity-8">
                        {{ $tc("connectSocial.socialAccounts.added") }} • {{ selectedPost.publishedAt  | timestampToString }}
                      </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 my-auto ps-0">
                    <div class="dropdown">
                      <button
                          v-if="selectedPost.publicId !== '-'"
                          class="btn btn-icon-only shadow-none text-dark mb-0"
                          type="button"
                          @click="redirectToLink(selectedPost.publicId)">
                        <i class="fas fa-link"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="card-body overflow-auto overflow-x-hidden">
                <template
                    v-for="comment in filteredCommentsList">
                  <comment-post
                      :ref="'comment_' + comment._id"
                      :key="comment._id"
                      :comment="comment"
                      :social="selectedSocial"
                      :active="comment._id === '001'"
                      @open-templates="openTemplates" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="card card-plain mb-4">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-lg-12 p-0">
                  <div class="d-flex flex-column h-100">
                    <h2 class="font-weight-bolder mb-0" style="color: #eb4869;">
                      {{ $tc('comment.no_new_message') }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
    <templateModal
      ref="templateModal"
      @use-template="useTemplate" />
  </div>
</template>
<script>
import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import CommentPost from "@/views/Engagement/commentPost.vue";
import templateModal from "@/views/Engagement/templateModal.vue";
import SocialRoundIcon from "@/views/ComposeFeed/SocialRoundIcon.vue";
import { getSocialProviders } from "@/apis/FacebookApi";
import Vue from "vue";
import { getNewComments, getUnAnswered } from "@/apis/CommentApi";
import LoadingIcon from "@/views/Components/loadingIcon.vue";
export default {
  name:"engagementOverview",
  components: {LoadingIcon, SocialRoundIcon, CommentPost, templateModal },
  data() {
    return {
      socials: [],
      selectedComment: '',
      selectedSocial : null,
      plugins: [new Arrow()],
      postList: [],
      commentsList: [],
      selectedPost: null,
    }
  },
  computed: {
    filteredPostList: function() {
      return this.postList.filter(post => this.selectedSocial.profileId === post.platformIds[0])
    },
    filteredCommentsList: function() {
      return this.selectedPost ? this.commentsList.filter(comment => comment.postId === this.selectedPost._id) : []
    },
  },
  async mounted() {
    this.loading = true;
    this.socials = this.$store.getters['socialProvider/getSocialProviders']
    this.$nextTick(() => {
      this.selectedSocial = this.socials[0];
    })
    getUnAnswered().then(res => {
      this.commentsList = res.data.comments;
      this.postList = res.data.posts;
      this.loading = false;
    })
      .catch((error) => {
        this.loading = false;
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
  },
  methods: {
    isNeutral(post) {
      return this.commentsList.filter(c => c.postId === post._id).some(c => c.sentiment === 'positive');
    },
    isPositive(post) {
      return this.commentsList.filter(c => c.postId === post._id).some(c => c.sentiment === 'neutral');
    },
    isNegative(post) {
      return this.commentsList.filter(c => c.postId === post._id).some(c => c.sentiment === 'negative');
    },
    openTemplates(commentId) {
      this.selectedComment = commentId;
      this.$refs.templateModal.showModal()
    },
    changeSelectedSocial(social, isActive) {
      this.selectedSocial = social
      this.selectedPost = null;
    },
    useTemplate(template) {
      this.$refs['comment_'+ this.selectedComment][0].selectTemplate(template.comment);
    },
    activatePost(post) {
      this.selectedPost = post;
    },
    socialCommentCount(social) {
      return this.commentsList.filter(c => c.providerId === social.profileId).length;
    },
    postCommentCount(post) {
      return this.commentsList.filter(c => c.postId === post._id).length;
    },
    redirectToLink(link) {
      window.open(link, '_blank');
    }
  }
}
</script>
<style lang="scss">
.sentiment-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.flicking-arrow-prev {
  left: -20px;
  width: 40px !important;
}
.flicking-arrow-next {
  right: -20px;
  width: 40px !important;
}
.avatar-status {
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -11px;
  font-size: 15px;
  color: white;
  width: 25px;
  line-height: 22px;
  height: 25px;
  z-index: 2;
  padding: 2px;
}
.galerie {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1300px;
  margin: 70px auto 0;
  /* background: crimson; */
}
.item {
  width: 105px;
  height: 105px;
  margin: 5px;
  overflow: hidden;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.post-selection .text-truncate{
  max-width: 200px;
}
</style>
