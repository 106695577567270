<template>
  <b-modal
    ref="cropImageModal"
    no-close-on-backdrop
    :title="$tc('compose.image_upload.crop_title')">
    <div>
      <b-dropdown
        id="dropdown_ratio"
        variant="light"
        class="m-2">
        <template #button-content>
          <img
            v-if="selectedRatio && selectedRatio.categorie === 'facebook'"
            class="img_button"
            src="../../assets/img/facebook_outline.png">
          <img
            v-if="selectedRatio && selectedRatio.categorie === 'instagram'"
            class="img_button"
            src="../../assets/img/instagram_outline.png">
          <span>{{ selectedRatio ? selectedRatio.label : 'News Feed' }}</span>
        </template>
        <div class="row">
          <div class="col-6">
            <label class="categorie"><img src="../../assets/img/facebook_outline.png"><span>FaceBook</span></label>
            <template v-for="(item, index) in ratioItemsFacebook">
              <b-dropdown-item
                :key="'filter1_'+index"
                @click="ratioItemSelected(item)">
                <div class="size">
                  <p class="title">
                    {{ item.label }}
                  </p>
                  <p class="pixels">
                    {{ item.value }}
                  </p>
                </div>
              </b-dropdown-item>
            </template>
          </div>
          <div class="col-6">
            <label class="categorie"><img src="../../assets/img/instagram_outline.png"><span>Instagram</span></label>
            <template v-for="(item, index) in ratioItemsInstagram">
              <b-dropdown-item
                :key="'filter2_'+index"
                @click="ratioItemSelected(item)">
                <div class="size">
                  <p class="title">
                    {{ item.label }}
                  </p>
                  <p class="pixels">
                    {{ item.value }}
                  </p>
                </div>
              </b-dropdown-item>
            </template>
          </div>
        </div>
      </b-dropdown>
      <vue-cropper
        ref="cropper"
        :src="image"
        :aspect-ratio="selectedRatio?.ratio"
        :zoomable="false"
        alt="Source Image" />
    </div>
    <div
      id="result"
      style="max-height: 500px;max-width: 500px;">
      <img :src="croppedImage">
    </div>
    <div
      slot="modal-footer"
      class="w-100">
      <b-button
        variant="outline-danger"
        class="mb-0 d-none">
        Remove
      </b-button>
      <b-button
        variant="light"
        class="mb-0 me-3 float-end d-none">
        Go Back
      </b-button>
      <b-button
        variant="success"
        class="mb-0 float-end"
        @click="saveCropping">
        {{ $tc('compose.image_upload.crop') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'CropImageModal',
  components: {
    VueCropper
  },
  mixins: [],
  props: {
    image: {
      type: String,
      default: ''
    },
    post: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      file: null,
      croppedImage: null,
      selectedRatio: null,
      ratioItems: [
        { label: 'News feed', key:'news_feed', value: '1200x1200', ratio: 1/1, categorie: 'facebook' },
        { label: 'Mobile', key:'mobile', value: '1080x1920', ratio: 9/16, categorie: 'facebook' },
        { label: 'Cover Photo', key:'cover_photo', value: '830x312', ratio: 415/156, categorie: 'facebook' },
        { label: 'Event Photo', key:'event_photo', value: '1336x700', ratio: 334/175, categorie: 'facebook' },
        { label: 'Group Photo', key:'group_photo', value: '640x334', ratio: 320/167, categorie: 'facebook' },
        { label: 'Open Graph', key:'open_graph', value: '1200x630', ratio: 40/21, categorie: 'facebook' },
        { label: 'Feed', key:'feed', value: '1200x1200', ratio: 1/1, categorie: 'instagram' },
        { label: 'Landscape', key:'landscape', value: '1920x1080', ratio: 16/9, categorie: 'instagram' },
        { label: 'Story', key:'story', value: '1080*1920', ratio: 9/16, categorie: 'instagram' },
        { label: 'Portrait', key:'portrait', value: '1024x1280', ratio: 4/5, categorie: 'instagram' },
      ]
    }
  },
  computed: {
    ratioItemsFacebook() {
      return this.ratioItems.filter(item => item.categorie === 'facebook')
    },
    ratioItemsInstagram() {
      return this.ratioItems.filter(item => item.categorie === 'instagram')
    }
  },
  watch: {
  },
  mounted: function () {
    this.selectedRatio = this.ratioItems[0]
  },
  methods: {
    show() {
      if (this.post.publishType === 'STORIES') {
        this.selectedRatio = this.ratioItems[8]
      } else {
        this.selectedRatio = this.ratioItems[0]
      }
      this.$nextTick(() => {
        this.$refs.cropImageModal.show()
      })
    },
    hide() {
      this.$refs.cropImageModal.hide()
    },
    saveCropping() {
      const cropper = this.$refs.cropper
      this.hide()
      this.$emit('save-image', cropper)
    },
    ratioItemSelected(item) {
      this.selectedRatio = item
      const cropper = this.$refs.cropper
      cropper.setAspectRatio(item.ratio)
    }
  }
}
</script>

<style lang="scss">
.modal-header {
  border: none;
  .close {
    border: none;
    outline: none;
    background: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.modal-body{
  padding-top: 0;
}
#dropdown_ratio {
  position: absolute;
  left: 0.3rem;
  top: 0.3rem;
  z-index: 9999;
}
.dropdown-menu {
  padding: 0.5rem !important;
  min-width: 300px !important;
}
.dropdown-toggle {
  text-transform: capitalize !important;
  padding: 0.25rem 0.25rem !important;
  margin: 0 !important;
  color: #000000;
  font-weight: 400 !important;
  background-color: rgba(255,255,255,0.7) !important;
  span {
    vertical-align: middle;
    margin: 0 0.25rem !important;
  }
  .img_button {
    width: 25px;
  }
}
.dropdown-toggle:after {
  margin: 0 10px;
  font-size: 12px !important;
}
.size {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  background: #fafafa;
  p{
    margin: 0;
    line-height: 1.3;
  }
  .title {
    font-size: 0.9rem;
    color: #000000;
    font-weight: 400;
  }
  .pixels {
    font-size: 0.7rem;
    color: #718096;
    font-weight: 300;
  }
}
.dropdown-item {
  padding: 0.3rem 0rem !important;
}
.categorie {
  margin: 0;
  span {
    font-size: 0.85rem;
    vertical-align: middle;
  }
  img {
    width: 30px;
  }
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: transparent !important;
  .size {
    background-color: #e9ecef;
  }
}
</style>
