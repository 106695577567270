import axios from 'axios';
const baseURL= 'http://localhost:3000'
const googleInstance = axios.create({
    baseURL: baseURL,
    timeout: 90000,
    headers: {
        'Content-Type': 'application/json'
    }
})
export async function getClientList(accessToken) {
        let data = JSON.stringify({
            accessToken: accessToken
        })
        return googleInstance.post(baseURL + '/ads/ai/googleCustomer', data)
          .then(response => {
            return response
          })
}

export async function getKeywords(reportId) {
    let data = JSON.stringify({
        reportId: reportId
    })
    return googleInstance.post(baseURL + '/ads/ai/getKeywords', data)
        .then(response => {
            return response
        })
}

export async function generateKeywords(accountId, customerId, customerName, managerName, managerId) {
    let data = JSON.stringify({
        accountId: accountId,
        customerId: customerId,
        customerName: customerName,
        managerName: managerName,
        managerId: managerId
    })
    return googleInstance.post(baseURL + '/ads/ai/generateKeywords', data)
        .then(response => {
            return response
        })
}

export async function generatePdf(reportId) {
    let data = JSON.stringify({
        reportId: reportId
    })
    return googleInstance.post(baseURL + '/ads/ai/generatePdf', data, {
        responseType: 'blob'
    }).then(response => {
            return response
        })
}



