<template>
  <section>
    <div class="row">
      <div class="col-12">
        <vue-cal
          class="vuecal--rounded-theme vuecal--green-theme schedule-calendar"
          xsmall
          hide-view-selector
          :time="false"
          active-view="month"
          :disable-views="['week','day']"
          :selected-date="scheduleObject.selectedDate"
          style="height: 300px"
          locale="fr"
          @cell-focus="scheduleObject.selectedDate = $event">
          <template #arrow-prev>
            <i class="fas fa-arrow-left" />
          </template>
          <template #arrow-next>
            <i class="fas fa-arrow-right" />
          </template>
        </vue-cal>
      </div>
      <div class="col-12">
        <div class="row">
          <div
            class="pb-4">
            <div
              class="pb-0"
              style="height: 224px;width: 75%;margin:auto;">
              <p class="mb-0 subtitle-card text-center mt-3">
                {{ $tc('compose.time_to_post') }}
              </p>
              <bar
                v-if="data.datasets[0].data.length > 0"
                :data="data"
                :options="options" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div
          class="row mx-0 mt-3">
          <p class="mb-0 subtitle-card text-center">
            {{ $tc('compose.time_selection') }}
          </p>
          <vue-timepicker
            v-model="scheduleObject.selectedTime"
            class="col-7 p-0 m-auto mt-3"
            input-class="timepicker-input"
            placeholder="00:00">
            <template
              #icon>
              <img
                alt="icon-clock"
                src="/src/assets/img/custom-clock.png" />
            </template>
          </vue-timepicker>
          <p
            v-if="errorRequired && validating"
            class="text-error">
            {{ $tc('compose.errors.schedule_required') }}
          </p>
          <p
            v-if="errorPast && validating"
            class="text-error">
            {{ $tc('compose.errors.schedule_past') }}
          </p>

        </div>
      </div>
      <div class="col-12">
        <div class="col p-0">
          <button
            type="button"
            class="btn bg-gradient-primary mt-4 float-end"
            @click="schedulePost">
            {{ $tc('generic.save') }}
          </button>
          <button
            type="button"
            class="btn bg-gradient-secondary mt-4 mx-2 float-end"
            @click="$emit('hideModal')">
            {{ $tc('generic.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueCal from 'vue-cal'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import 'vue-cal/dist/vuecal.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import {getHoursToPost} from "@/apis/AnalyticsApi";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    name: 'scheduleModal',
    components: {
      VueCal,
      Bar,
      VueTimepicker
    },
    props: {
      socialsSelected: {
        type: Array,
        default: () => []
      },
      post: {
        type: Object,
        default: () => {}
      }
    },
    data() {
        return {
          validating: false,
          data: {
              labels: [
                  '00',
                  '03',
                  '06',
                  '09',
                  '12',
                  '15',
                  '18',
                  '21',
              ],
              datasets: [
                  {
                      label: 'count of users',
                      backgroundColor: '#3a416f',
                      data: [],
                      barPercentage: 0.5,
                      maxBarThickness: 8,
                      minBarLength: 2,
                      borderRadius: 25
                  }
              ],
          },
          options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        border: {
                            display: false
                        }
                    }
                },
              plugins: {
                legend: {
                  display: false
                }
              }
            },
          scheduleObject: {
            selectedTime: null,
            selectedDate: null
          }
        }
    },
  computed: {
    errorRequired: function() {
      if (this.scheduleObject.selectedTime && this.scheduleObject?.selectedDate) {
        return false
      }
      return true
    },
    errorPast: function() {
      if (this.scheduleObject?.selectedTime && this.scheduleObject?.selectedDate) {
        const date = new Date(this.scheduleObject.selectedDate)
        date.setHours(this.scheduleObject.selectedTime.HH, this.scheduleObject.selectedTime.mm,0 )
        return date.getTime() <= new Date().getTime()
      }
      return false
    }
  },
  mounted() {
    let date = new Date();
    if (this.post && this.post.scheduledAt) {
      date = new Date(this.post.scheduledAt)
      this.scheduleObject.selectedDate = date
    }
    this.scheduleObject.selectedTime = {
      "HH": ("0" + date.getHours()).slice(-2),
      "mm": date.getMinutes() + ""
    };
    this.validating = false
    getHoursToPost(this.socialsSelected[0].profileId).then((response) => {
      this.data.datasets[0].data = response.data.data
    }).catch((error) => {
      console.log(error)
    });
  },
  beforeDestroy() {
  },
  methods: {
    isValidate() {
      this.validating = true
      if (this.errorRequired || this.errorPast) {
        return false
      }
      return true
    },
    schedulePost() {
      if (this.isValidate()) {
        this.$emit('schedulePost', this.scheduleObject);
      }
    }
  }
}
</script>
<style lang="scss">
  .timepicker-input{
    width: 100% !important;
  }
  :deep(.time-picker .dropdown.drop-down){
    width: 100% !important;
  }
  .schedule-calendar {
    .vuecal__cell--today .vuecal__cell-content{
      border-color: #42b983 !important;
      background-color: #f1faf7 !important;
      color: #333 !important;
    }
    .vuecal__cell--selected .vuecal__cell-content{
      background-color: #42b983 !important;
      color: #fff !important;
    }
  }
</style>