<template>
  <section>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div>
            <div class="card-body row d-flex">
              <Flicking
                :options="{ align: 'prev', bound: true, circular: false }"
                :plugins="pluginsFlick">
                <social-round-icon
                  v-for="social in socials"
                  :key="social._id"
                  :ref="social._id"
                  :social="social"
                  :is-active="selectedSocial === social"
                  @is-clicked="changeSelectedSocial" />
                  <span
                    slot="viewport"
                    class="flicking-arrow-prev is-thin">
                  </span>
                  <span
                    slot="viewport"
                    class="flicking-arrow-next is-thin">
                  </span>
              </Flicking>
            </div>
          </div>
        </div>
      </div>
      <loading-icon
        v-if="loading"
        :size="'medium'"
        :position="'center'"
        class="ai-loader" />
      <div v-else>
        <div class="row">
          <!-- Cards -->
          <div class="col-12 col-xl-6">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="card  mb-4">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">
                            {{ $tc('analytics.posts') }}
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            {{ posts }}
                            <span
                              class="text-sm font-weight-bolder"
                              :class=" percentageDifference(posts, postsPast) >= 0 ? 'text-success': 'text-danger'">
                              {{ percentageDifference(posts, postsPast) > 0 ? '+' : '' }}
                              {{ percentageDifference(posts, postsPast) }}%
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i class="svg-top fas fa-bullhorn"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card  mb-4">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">
                            {{ $tc('analytics.comments') }}
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            {{ comments }}
                            <span
                              class="text-sm font-weight-bolder"
                              :class=" percentageDifference(comments, commentsPast) >= 0 ? 'text-success': 'text-danger'">
                              {{ percentageDifference(comments, commentsPast) > 0 ? '+' : '' }}
                              {{ percentageDifference(comments, commentsPast) }}%
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i class="svg-top fas fa-comments"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card ">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">
                            {{ $tc('analytics.engagement_rate') }}
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            {{ engagementRate }}%
                            <span
                              v-if="engagementPast !== -1"
                              class="text-sm font-weight-bolder"
                              :class=" percentageDifference(engagementRate, engagementPast) >= 0 ? 'text-success': 'text-danger'">
                              {{ percentageDifference(engagementRate, engagementPast) > 0 ? '+' : '' }}
                              {{ percentageDifference(engagementRate, engagementPast) }}%
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i class="svg-top fas fa-handshake"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 mt-sm-0 mt-4">
                <div class="card mb-4">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">
                            {{ $tc('analytics.impressions') }}
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            {{ impressions }}
                            <span
                              v-if="impressionsPast !== -1"
                              class="text-sm font-weight-bolder"
                              :class="percentageDifference(impressions, impressionsPast) >= 0 ? 'text-success': 'text-danger'">
                              {{ percentageDifference(impressions, impressionsPast) > 0 ? '+' : '' }}
                              {{ percentageDifference(impressions, impressionsPast) }}%
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i class="svg-top fas fa-eye"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-4">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">
                            {{ $tc('analytics.commentdelay') }}
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            {{ timestampToString(commentsDelay) }}
                            <span
                              class="text-sm font-weight-bolder"
                              :class=" percentageDifference(commentsDelay, commentsDelayPast) >= 0 ? 'text-success': 'text-danger'">
                              {{ percentageDifference(commentsDelay, commentsDelayPast) > 0 ? '+' : '' }}
                              {{ percentageDifference(commentsDelay, commentsDelayPast) }}%
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i class="svg-top fas fa-hourglass-half"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card ">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-8">
                        <div class="numbers">
                          <p class="text-sm mb-0 text-capitalize font-weight-bold">
                            {{ $tc('analytics.new_followers') }}
                          </p>
                          <h5 class="font-weight-bolder mb-0">
                            +{{ followers }}
                            <span
                              v-if="followersPast !== -1"
                              class="text-sm font-weight-bolder"
                              :class=" percentageDifference(followers, followersPast) >= 0 ? 'text-success': 'text-danger'">
                              {{ percentageDifference(followers, followersPast) > 0 ? '+' : '' }}
                              {{ percentageDifference(followers, followersPast) }}%
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                          <i class="svg-top fas fa-user-plus"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Best Posts -->
          <div class="col-12 col-xl-6 mt-4 m-xl-0">
            <div class="card ">
              <div class="card-header pb-0 p-3">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">
                    {{ $tc('analytics.best_posts.title') }}
                  </h6>
                </div>
              </div>
              <div
                class="table-responsive table-best-posts" 
                style="margin-bottom: 0.5rem;">
                <table class="table align-items-center ">
                  <tbody>
                    <tr
                      v-for="post in bestPosts"
                      :key="post._id">
                      <td class="w-30">
                        <div class="d-flex px-2 py-1 align-items-center">
                          <div>
                            <img
                              :src="post.medias.length > 0 ? post.medias[0].url : require('@/assets/img/text-placeholder.jpg')"
                              style="width: 50px; height: 50px;border-radius:5px;">
                          </div>
                          <div class="ms-4">
                            <p class="text-xs font-weight-bold mb-0">
                              {{ $tc('analytics.best_posts.caption') }}
                            </p>
                            <h6 class="text-sm mb-0 text-truncate w-35">
                              {{ post.message }} 
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <h6 class="text-sm mb-0">
                            {{ post.comments }} 
                            <i class="fas fa-comment" />
                          </h6>
                          <h6 class="text-sm mb-0">
                            {{ post.likes }} 
                            <i class="fas fa-heart" />
                          </h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="text-xs font-weight-bold mb-0">
                            {{ $tc('compose.labels.label') }}
                          </p>
                          <h6 class="text-sm mb-0">
                            {{ post.labels.length > 0 ? getLabelName(post.labels[0]) : '-' }}
                          </h6>
                        </div>
                      </td>
                      <!--<td class="align-middle text-sm">
                        <div class="col text-center">
                          <p class="text-xs font-weight-bold mb-0">
                            {{ $tc('analytics.best_posts.engagement_rate') }}
                          </p>
                          <h6 class="text-sm mb-0">29.9%</h6>
                        </div>
                      </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-6 mb-lg-0 mb-4">
            <div class="card z-index-2">
              <div class="card-body p-3 pb-0">
                <h6 class="ms-2">
                  {{ $tc('compose.time_to_post') }}
                </h6>
                <div
                  v-if="data.barData.datasets[0].data.length > 0"
                  class="bg-gradient-dark border-radius-lg py-3 pe-1 mb-3">
                  <bar
                    :data="data.barData"
                    :options="data.barOptions" />
                </div>
                <div v-else>
                  <p
                    class="text-error ms-2">
                    {{ $tc('loadingErrors.best_hours_error') }}
                  </p>
                </div>
                <div class="container border-radius-lg">
                  <div class="row">
                    <div class="col-4 py-3 ps-0">
                      <div class="d-flex mb-2">
                        <div class="icon icon-shape icon-xxs shadow border-radius-sm bg-gradient-primary text-center me-2 d-flex align-items-center justify-content-center">
                          <svg width="10px" height="10px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>document</title>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                <g transform="translate(1716.000000, 291.000000)">
                                  <g transform="translate(154.000000, 300.000000)">
                                    <path class="color-background" d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                                    <path class="color-background" d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p class="text-xs mt-1 mb-0 font-weight-bold">
                          {{ $tc('analytics.best_posts.best_day') }}
                        </p>
                      </div>
                      <h4
                        class="font-weight-bolder">
                        {{ bestDay !== '-' ? $tc('generic.days.' + bestDay.toLowerCase()) : '- ' }}
                      </h4>
                    </div>
                    <div class="col-4 py-3 ps-0">
                      <div class="d-flex mb-2">
                        <div class="icon icon-shape icon-xxs shadow border-radius-sm bg-gradient-info text-center me-2 d-flex align-items-center justify-content-center">
                          <svg width="10px" height="10px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>spaceship</title>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                <g transform="translate(1716.000000, 291.000000)">
                                  <g transform="translate(4.000000, 301.000000)">
                                    <path class="color-background" d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                    <path class="color-background" d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                    <path class="color-background" d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                    <path class="color-background" d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" opacity="0.598539807"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p class="text-xs mt-1 mb-0 font-weight-bold">
                          {{ $tc('analytics.best_posts.best_etiquette') }}
                        </p>
                      </div>
                      <h4 class="font-weight-bolder">
                        {{ getLabelName(bestEtiquette) }}
                      </h4>
                    </div>
                    <div class="col-4 py-3 ps-0">
                      <div class="d-flex mb-2">
                        <div class="icon icon-shape icon-xxs shadow border-radius-sm bg-gradient-warning text-center me-2 d-flex align-items-center justify-content-center">
                          <svg width="10px" height="10px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>credit-card</title>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                <g transform="translate(1716.000000, 291.000000)">
                                  <g transform="translate(453.000000, 454.000000)">
                                    <path class="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                    <path class="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p class="text-xs mt-1 mb-0 font-weight-bold">{{ $tc('analytics.best_posts.best_type_of_post') }}</p>
                      </div>
                      <h4 class="font-weight-bolder">
                        {{ bestTypeOfPost === '-' ? '-' : $tc('compose.publish_type.' + bestTypeOfPost) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 ms-auto">
            <div class="card" style="min-height: 330px;">
              <div class="card-header pb-0 p-3">
                <div class="d-flex align-items-center">
                  <h6 class="mb-0">
                    {{ $tc('analytics.comments_card.title') }}
                  </h6>
                  <button
                    type="button"
                    class="btn btn-icon-only btn-rounded btn-outline-secondary mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
                    style="color: #344767;border-color: #344767;"
                    v-b-tooltip.hover.left
                    :title="$tc('analytics.comments_card.tooltip')">
                    <i class="fas fa-info" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div
                class="card-body p-3"
                style="padding: 28px 1rem !important;overflow: hidden;">
                <div class="row">
                  <div class="col-5 text-center">
                    <div
                      v-if="data.chartData.datasets[0].data.length > 0" 
                      class="chart">
                      <doughnut
                        :options="data.chartOptions"
                        :data="data.chartData"
                        :dataset-id-key="'label'"
                        :plugins="[]"
                        :class="'doughnut-chart'"
                        :styles="{}"
                        :width="250"
                        :height="250" />
                    </div>
                    <h4
                      class="font-weight-bold mt-n8"
                      style="left: 50%;transform: translate(-50%, -50%); position: absolute;">
                      <span>{{ comments }}</span>
                      <span class="d-block text-body text-sm">
                        {{ $tc('analytics.comments') }}
                      </span>
                    </h4>
                  </div>
                  <div class="col-7">
                    <div class="table-responsive table-comment-sentiment">
                      <table class="table align-items-center mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <div class="d-flex px-2 py-0">
                                <span class="comment-badge negative">
                                </span>
                                <div class="d-flex flex-column justify-content-center">
                                  <h6 class="mb-0 text-sm">
                                    {{ $tc('analytics.comments_card.negative') }}
                                    <span 
                                      class="text-sm font-weight-bolder ms-1"
                                      :class="percentageDifference(sentimentsCurrent.negative, sentimentsPast.negative) >= 0 ? 'text-success': 'text-danger'">
                                      {{ percentageDifference(sentimentsCurrent.negative, sentimentsPast.negative) >= 0 ? '+' : '' }}
                                      {{ percentageDifference(sentimentsCurrent.negative, sentimentsPast.negative ) }}%
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-xs font-weight-bold">  {{ sentimentsCurrent.negative }}% </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex px-2 py-0">
                                <span class="comment-badge neutral">
                                </span>
                                <div class="d-flex flex-column justify-content-center">
                                  <h6 class="mb-0 text-sm">
                                    {{ $tc('analytics.comments_card.neutral') }}
                                    <span 
                                      class="text-sm font-weight-bolder ms-1"
                                      :class="percentageDifference(sentimentsCurrent.neutral, sentimentsPast.neutral) >= 0 ? 'text-success': 'text-danger'">
                                      {{ percentageDifference(sentimentsCurrent.neutral, sentimentsPast.neutral) >= 0 ? '+' : '' }}
                                      {{ percentageDifference(sentimentsCurrent.neutral, sentimentsPast.neutral ) }}%
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-xs font-weight-bold"> {{ sentimentsCurrent.neutral }}% </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="d-flex px-2 py-0">
                                <span class="comment-badge positive">
                                </span>
                                <div class="d-flex flex-column justify-content-center">
                                  <h6 class="mb-0 text-sm">
                                    {{ $tc('analytics.comments_card.positive') }}
                                    <span
                                      class="text-sm font-weight-bolder ms-1"
                                      :class=" percentageDifference(sentimentsCurrent.positive, sentimentsPast.positive) >= 0 ? 'text-success': 'text-danger'">
                                      {{ percentageDifference(sentimentsCurrent.positive, sentimentsPast.positive) >= 0 ? '+' : '' }}
                                      {{ percentageDifference(sentimentsCurrent.positive, sentimentsPast.positive ) }}%
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle text-center text-sm">
                              <span class="text-xs font-weight-bold"> {{ sentimentsCurrent.positive }}% </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="data.lineData.labels.length > 0"
          class="row mt-4">
          <div class="col-lg-12">
            <div class="card z-index-2">
              <div class="card-header pb-0">
                <h6> {{$tc('analytics.engagement_rate')}}</h6>
                <p class="text-sm">
                  <i class="fa fa-arrow-up text-success" aria-hidden="true"></i>
                  <span class="font-weight-bold"> 13% </span>
                  {{$tc('analytics.more_than')}} juin
                </p>
              </div>
              <div class="card-body p-3">
                <line-chart-generator
                  :options="data.lineOptions"
                  :data="data.lineData"
                  :chart-id="'line-chart'"
                  :dataset-id-key="'label'"
                  :width="400"
                  :height="400" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {Bar} from "vue-chartjs";
import {Doughnut} from "vue-chartjs";
import { Line as LineChartGenerator } from 'vue-chartjs'
import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  ArcElement,
  CategoryScale,
  PointElement
} from 'chart.js'
import SocialRoundIcon from "@/views/ComposeFeed/SocialRoundIcon.vue";
import {getSocialProviders} from "@/apis/FacebookApi";
import {getHoursToPost, getSocialAnalytics, getBestOf, getDbAnalytics} from "@/apis/AnalyticsApi";
import Vue from "vue";
import LoadingIcon from "@/views/Components/loadingIcon.vue";
import {getLabels} from "@/apis/LabelApi";

ChartJS.register(Title, Tooltip, Legend, ArcElement, LineElement, LinearScale, CategoryScale, PointElement)

export default {
  name:"AnalyticsOverview",
  components: {
    SocialRoundIcon,
    Bar,
    Doughnut,
    LineChartGenerator,
    LoadingIcon
  },
  data() {
    return {
      labels: [],
      pluginsFlick: [new Arrow()],
      selectedSocial : null,
      socials: [],
      loading: true,
      bestDay: '',
      bestEtiquette: '',
      bestTypeOfPost: '',
      bestPosts: [],
      engagementRate: 0,
      engagementPast: 0,
      followers: 0,
      followersPast: 0,
      impressions: 0,
      impressionsPast: 0,
      posts: 0,
      postsPast: 0,
      comments: 0,
      commentsPast: 0,
      commentsDelay: 0,
      commentsDelayPast: 0,
      sentimentsCurrent: 0,
      sentimentsPast: 0,
      data: {
        barData : {
          labels: [
            '00',
            '03',
            '06',
            '09',
            '12',
            '15',
            '18',
            '21',
          ],
          datasets: [
            {
              label: 'count of users',
              backgroundColor: '#fff',
              data: [],
              barPercentage: 0.5,
              maxBarThickness: 8,
              minBarLength: 2,
              borderRadius: 25
            }
          ]
        },
        barOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: { color: '#fff', beginAtZero: true },
              grid: {
                display: false
              }
            },
            y: {
              ticks: { color: '#fff', beginAtZero: true },
              border: {
                display: false
              }
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        },
        chartData: {
          labels: ['Commentaires Négatifs', 'Commentaires Neutres', 'Commentaires Positifs'],
          datasets: [
            {
              backgroundColor: ['#d13282', '#0761d4', '#82d616'],
              data: []
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: 99,
          plugins: {
            legend: {
              display: false,
            }
          }
        },
        lineData: {
          labels: [],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#0761d4',
              data: [],
              fill: false,
              borderColor: '#0761d4',
              tension: 0.1
            },
            {
              label: 'Data Two',
              backgroundColor: '#d13282',
              data: [],
              fill: false,
              borderColor: '#d13282',
              tension: 0.1
            }
          ]
        },
        lineOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            }
          }
        }
      }

    }
  },
  mounted() {
      this.socials = this.$store.getters['socialProvider/getSocialProviders']
      this.$nextTick(() => {
        this.selectedSocial = this.socials[0]
        this.loadData()
      })
    getLabels().then(response => {
      this.labels = response.data.labels
    })
  },
  methods: {
    getLabelName(labelId) {
      const label = this.labels.find(label => label._id === labelId)
      return label ? label.title : ''
    },
    loadData() {
      const until = Date.now();
      const since = until - (30 * 24 * 60 * 60 * 1000);
      this.loading = true
      const promises = [
        getHoursToPost(this.selectedSocial.profileId, since, until),
        getSocialAnalytics(this.selectedSocial.profileId, since, until),
        getBestOf(this.selectedSocial.profileId, since, until),
        getDbAnalytics(this.selectedSocial.profileId, since, until)
      ]
      this.resetData()
      Promise.allSettled(promises)
          .then((response) => {
            this.loading = false
            //getHoursToPost results
            if (response[0].value && response[0].status === 'fulfilled') {
              this.data.barData.datasets[0].data = response[0].value.data.data
            }
            //getSocialAnalytics results
            if (response[1].value && response[0].status === 'fulfilled') {
              this.followers = response[1].value.data.followers
              this.followersPast = response[1].value.data.followersPast
              this.impressions = response[1].value.data.impressions
              this.impressionsPast = response[1].value.data.impressionsPast
              this.engagementRate = response[1].value.data.engagements > 0 ? (response[1].value.data.engagements * 100 / this.impressions).toFixed(0) : 0
              this.engagementPast = response[1].value.data.engagementsPast > 0 ? (response[1].value.data.engagementsPast * 100 /this.impressionsPast).toFixed(0) : 0
              this.engagementPast = response[1].value.data.engagementsPast !== -1 ? response[1].value.data.engagementsPast : -1
              this.data.lineData.datasets[0].data = response[1].value.data.engagementsList.slice(0, 30).map(item => item.value)
              this.data.lineData.datasets[1].data = response[1].value.data.engagementsPastList.slice(0, 30).map(item => item.value)
              this.data.lineData.labels = response[1].value.data.engagementsList.slice(0, 30).map(item => this.formatDateToDDMM(item.end_time))
            }
            //getBestOf results
            if (response[2].value && response[0].status === 'fulfilled') {
              this.bestPosts = response[2].value.data.bestPosts
              this.bestDay = response[2].value.data.day
              this.bestEtiquette = response[2].value.data.label
              this.bestTypeOfPost = response[2].value.data.publishType
            }
            //getDbAnalytics results
            if (response[3].value && response[0].status === 'fulfilled') {
              this.posts = response[3].value.data.currentPosts
              this.postsPast = response[3].value.data.pastPosts
              this.comments = response[3].value.data.currentComments.length
              this.commentsPast = response[3].value.data.pastComments.length
              const sentimentsCurrent = response[3].value.data.currentComments.reduce((acc, curr) => {
                if (acc[curr.sentiment]) {
                  acc[curr.sentiment]++
                } else {
                  acc[curr.sentiment] = 1
                }
                return acc
              }, {})
              this.sentimentsCurrent = {
                negative: sentimentsCurrent.negative && this.comments > 0 ?
                          (sentimentsCurrent.negative / this.comments * 100).toFixed(0) 
                          : 0,
                positive: sentimentsCurrent.positive && this.comments > 0 ?
                          (sentimentsCurrent.positive / this.comments * 100).toFixed(0) 
                          : 0,
                neutral: sentimentsCurrent.neutral && this.comments > 0 ?
                          (sentimentsCurrent.neutral / this.comments * 100).toFixed(0) 
                          : 0,
              }
              const sentimentsPast = response[3].value.data.pastComments.reduce((acc, curr) => {
                if (acc[curr.sentiment]) {
                  acc[curr.sentiment]++
                } else {
                  acc[curr.sentiment] = 1
                }
                return acc
              }, {})
              this.sentimentsPast = {
                negative: sentimentsPast.negative && this.commentsPast > 0 ?
                          (sentimentsPast.negative / this.commentsPast * 100).toFixed(0) 
                          : 0,
                positive: sentimentsPast.positive && this.commentsPast > 0 ?
                          (sentimentsPast.positive / this.commentsPast * 100).toFixed(0) 
                          : 0,
                neutral: sentimentsPast.neutral && this.commentsPast > 0 ?
                          (sentimentsPast.neutral / this.commentsPast * 100).toFixed(0) 
                          : 0,
              }
              this.data.chartData.datasets[0].data = [
                sentimentsCurrent.negative || 0,
                sentimentsCurrent.neutral || 0,
                sentimentsCurrent.positive || 0
              ]
              this.commentsDelay = this.calculateAverageResponseTime(response[3].value.data.currentComments)
              this.commentsDelayPast = this.calculateAverageResponseTime(response[3].value.data.pastComments)
            }
            if(response[0].status === 'rejected' || response[1].status === 'rejected' || response[2].status === 'rejected' || response[3].status === 'rejected') {
              this.data.chartData.datasets[0].data = [0, 0, 0]
              this.loading = false
              this.$emit('loading', false)
              Vue.toasted.show(this.$tc('loadingErrors.analyticsError'), { type: 'error' })
            }
          })
    },
    resetData() {
      this.data.barData.datasets[0].data = []
      this.data.lineData.labels = []
      this.posts = 0
      this.postsPast = 0
      this.sentimentsPast = []
      this.data.chartData.datasets[0].data = []
      this.comments = 0
      this.commentsPast = 0
      this.commentsDelay = 0
      this.commentsDelayPast = 0
      this.sentimentsCurrent = 0
      this.bestPosts = []
      this.bestDay = '-'
      this.bestEtiquette = '-'
      this.bestTypeOfPost = '-'
    },
    changeSelectedSocial(social, active) {
      this.selectedSocial = social
      this.$nextTick(() => {
        this.loadData()
      })
    },
    formatDateToDDMM(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      return `${day}-${month}`;
    },
    percentageDifference(num1, num2) {
      const difference = Math.abs(num1 - num2);
      const average = (num1 + num2) / 2;
      const pre = num1 >= num2 ? '+' : '-';
      if (average === 0 || isNaN(average)) {
        return 0
      }
      const result = (difference / average) * 100;
      return num1 >= num2 ? result.toFixed(0) : (result*-1).toFixed(0);
    },
    calculateAverageResponseTime(comments) {
      // Filter out items with empty answerTime
      const validComments = comments.filter(comment => comment.answerTime !== '');
      // Calculate the time differences
      if (validComments.length === 0) {
        return 0
      }
      const timeDifferences = validComments.map(comment => {
          return parseInt(comment.answerTime) - parseInt(comment.createdTime);
      });
      // Calculate the total time difference
      const totalTimeDifference = timeDifferences.reduce((acc, curr) => acc + curr, 0);
      // Calculate the average time difference
      const averageTimeDifference = totalTimeDifference / validComments.length;
      return averageTimeDifference
    },
    timestampToString(averageTimeDifference) {
      const hours = Math.floor(averageTimeDifference / 3600000);
      const minutes = Math.floor((averageTimeDifference % 3600000) / 60000);
      return (hours > 0 ? `${hours}h` : '') + `${minutes}min`;
    }
  }
}
</script>
<style scoped lang="scss">
.svg-top{
  top: 10px;
  font-size: 22px;
  color: white;
}
.comment-badge {
  margin-right: 5px;
  border-radius: 5px;
  width: 15px;
  height: 15px;
    &.negative {
      background-image: linear-gradient(310deg, #ff66a3, #d13282);
    }
    &.positive {
      background-image: linear-gradient(310deg, #82d616, #76bf18);
    }
    &.orders {
      background-image: linear-gradient(310deg, #ffdb4d, #ffc412);
    }
    &.question {
      background-image: linear-gradient(310deg, #c075d9, #8e41b4);
    }
    &.neutral {
      background-image: linear-gradient(310deg, #3392ff, #0761d4);
    }
}
.w-35 {
  width: 120px !important;
}
.table-comment-sentiment {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.table-best-posts {
  min-height: 232px !important;
}
</style>