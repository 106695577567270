<template>
  <section>
    <div class="row">
      <div class="col-12">
        <video
          id="videoRef"
          ref="videoRef"
          style="max-width: 100%"
          :src="videoSrc" />
        <div style="z-index: 99;
            margin-top: -41px;
            padding-top: 7px;
            width: 100%;
            background: #2e7ed24a;">
          <input
            v-model="currentTime"
            type="range"
            :max="videoDuration"
            step="0.1"
            style="width: 100%;"
            @input="seekVideo" />
        </div>
      </div>
      <div class="col-12">
        <div class="col p-0">
          <button
            type="button"
            class="btn bg-gradient-primary mt-4 float-end"
            @click="saveTime">
            {{ $tc('generic.save') }}
          </button>
          <button
            type="button"
            class="btn bg-gradient-secondary mt-4 mx-2 float-end"
            @click="$emit('hideModal')">
            {{ $tc('generic.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'scheduleModal',
  props: {
    videoSrc: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: true,
      videoDuration: 0,
      currentTime: 0,
    };
  },
  mounted() {
    const myVideo = document.getElementById("videoRef");
    const current = this ;
    myVideo.onloadedmetadata = function() {
      current.videoDuration = this.duration;
    };
  },
  methods: {
    seekVideo() {
      this.$refs.videoRef.currentTime = this.currentTime;
    },
    saveTime() {
      this.$emit('thumbnailReel', this.currentTime);
      this.$emit('hideModal');
    },
  },
};
</script>
<style lang="scss">

</style>