<template>
  <section>
    <loading-icon
      v-if="loading"
      :size="'medium'"
      :position="'center'" />
    <template v-else >
      <div class="card card-plain mb-4">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-column h-100">
                <h2 class="font-weight-bolder mb-0">
                  {{ $tc("connectSocial.socialAccounts.title") }}
                </h2>
                <p class="mb-0 font-weight-bold text-sm">
                  {{ $tc("connectSocial.socialAccounts.subtitle") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(social,key) in socials"
          :key="key"
          class="col-lg-2 col-sm-6">
          <social-box
            :social="social"
            @refresh="refreshSocial"
            @remove="deleteSocial" />
        </div>
        <div class="col-lg-2 col-sm-6 mt-lg-0 mt-4">
          <div
            class="cursor-pointer card h-100 new-social"
            @click="toSocialBrands()">
            <div class="card-body d-flex flex-column justify-content-center text-center">
              <i
                class="fa fa-plus text-secondary mx-auto mb-3"
                aria-hidden="true" />
              <h5 class="text-secondary">
                {{ $tc("connectSocial.socialAccounts.new") }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <confirmation-modal ref="confirmDialogue" />
  </section>
</template>
<script>
import SocialBox from "@/views/SocialConnect/SocialBox.vue";
import LoadingIcon from "@/views/Components/loadingIcon.vue";
import {
  deleteSocialProvider,
  connectFacebook,
  connectInstagram,
  getSocialProviders,
  deletePost
} from "@/apis/FacebookApi";
import Vue from "vue";
import ConfirmationModal from "@/views/Components/ConfirmationModal.vue";

export default {
  name: "SocialList.vue",
  components: {ConfirmationModal, LoadingIcon, SocialBox },
  props: {
    token: {
      type: String,
      default: null
    },
    brand: {
      type: String,
      default: null
    },
    pageId: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      socials: [],
      loading: false
    }
  },
  mounted() {
    this.loading = true;
    if (this.token && this.brand && this.pageId) {
      this.loading = true;
      if (this.brand === 'facebook') {
        connectFacebook(this.token, this.pageId, 'facebook', true)
          .then(res => {
            this.getSocialProvidersList()
            Vue.toasted.show(this.$tc('savingSuccess.social_provider_connected'), { type: 'success' })
          }).catch(e => {
            this.getSocialProvidersList()
            console.log(e)
            if (e.response.status === 500) {
              Vue.toasted.show(this.$tc(`loadingErrors.${e.response.data.message}`), { type: 'error' })
            } else {
              Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
            }
          })
      }
      else if (this.brand === 'instagram') {
        connectInstagram(this.token, this.pageId, 'instagram', true)
          .then(res => {
            this.getSocialProvidersList()
            Vue.toasted.show(this.$tc('savingSuccess.social_provider_connected'), { type: 'success' })
          }).catch(e => {
            this.getSocialProvidersList()
            console.log(e)
            if (e.response.status === 500) {
              Vue.toasted.show(this.$tc(`loadingErrors.${e.response.data.message}`), { type: 'error' })
            } else {
              Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
            }
          })
      }
    } else {
      this.getSocialProvidersList();
    }
  },
  methods: {
    toSocialBrands() {
      this.$router.push({ name: 'BrandSelection' });
    },
    getSocialProvidersList() {
      getSocialProviders().then(res => {
        this.socials = res.data.socialProviderList;
        this.$store.dispatch('socialProvider/setSocialProviders', this.socials)
        this.$store.dispatch('socialProvider/setLoadedAt', new Date().getTime())
        this.loading = false;
      })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
        });
    },
    refreshSocial(social) {
      this.$router.push({ name: 'FacebookAuthenticate', params: { brand: social.socialBrand, refresh: 'true', pageId: social.parentProfileId }});
    },
    async deleteSocial(social) {
      const ok = await this.$refs.confirmDialogue.show({
        message: this.$tc('confirmationModal.socialDeletion')
      })
      if (ok) {
        this.loading = true
        deleteSocialProvider(social._id).then(res => {
          this.getSocialProvidersList();
          Vue.toasted.show(this.$tc('savingSuccess.social_provider_deleted'), {type: 'success'})
        }).catch((error) => {
          this.getSocialProvidersList();
          Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
        });
      }
    }
  }
};
</script>

<style scoped>
.new-social{
    min-height: 235px;
}
</style>