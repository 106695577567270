<template>
  <section class="col-8 m-auto">
    <div class="card card-plain mb-4">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-lg-6">
            <div class="d-flex flex-column h-100">
              <h2 class="font-weight-bolder mb-0">
                {{ $tc('connectSocial.brandSelection.title') }}
              </h2>
              <p class="mb-0 font-weight-bold text-sm">
                {{ $tc('connectSocial.brandSelection.subtitle') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <horizontal-brand
      :img="'facebook'"
      :title="'Facebook Page'"
      :subtitle="$tc('connectSocial.brandSelection.facebook')"
      @connect="connect('facebook')" />
    <horizontal-brand
      :img="'instagram'"
      :title="'Instagram Business'"
      :subtitle="$tc('connectSocial.brandSelection.instagram')"
      @connect="connect('instagram')" />
  </section>
</template>

<script>
import HorizontalBrand from "@/views/SocialConnect/HorizontalBrand.vue";

export default {
  name: "BrandSelection",
  components: { HorizontalBrand },
  methods: {
   connect(social) {
     if (social === 'instagram') {
       this.$router.push({ name: 'InstagramPage' });
     } else {
       this.$router.push({ name: 'FacebookAuthenticate', params: { brand: social }});
     }
   }
  }
};
</script>

<style scoped>

</style>