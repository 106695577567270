<template>
  <div>
    <div v-for="(chunk, index) in keywordChunks" :key="index">
      <pdf-table-card :items="chunk" />
    </div>
    <!-- Card 2: Chart -->
    <b-card
      style="margin-top:500px;"
      title="Chart Card" class="mb-3">
      <div>
        <bar
          id="chart"
          :data="barData"
          :options="barOptions" />
      </div>
    </b-card>
  </div>
</template>
<script>
import {Bar} from 'vue-chartjs'
import PdfTableCard from "@/views/Insider/PdfTableCard.vue";
import {getKeywords} from "@/apis/GoogleApi";

export default {
  name:"GoogleGeneration",
  components: {
    PdfTableCard,
    Bar
  },
  data() {
    return {
      barData : {
        labels: [
          '00',
          '00',
          '03',
          '06',
          '09',
          '12',
          '15',
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }]
      },
      keywordList: [],
      barOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: { color: '#fff', beginAtZero: true },
            grid: {
              display: false
            }
          },
          y: {
            ticks: { color: '#fff', beginAtZero: true },
            border: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  },
  computed: {
    keywordChunks() {
      const chunkSize = 10;
      return Array(Math.ceil(this.keywordList.length / chunkSize)).fill([]).map((_, index) => {
        return this.keywordList.slice(index * chunkSize, (index + 1) * chunkSize);
      });
    }
  },
  props: {
    reportId: {
      type: String,
      required: true
    }
  },
  mounted() {
    getKeywords(this.reportId).then((response) => {
      this.keywordList = response.data.keywords;
      window.dispatchEvent(new Event('chartRendered'));
    });
  },
}
</script>
<style lang="scss">
.header {
  margin: 10px 20px;
  border-left: 7px solid red;
  padding-left: 15px;
}
.sub-header {
  margin: 10px 20px;
  padding: 2px;
  text-align: center;
  background: red;
  h4,h6 {
    color: white;
  }
}
</style>

