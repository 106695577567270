<template>
  <section
    :class="hideNavigation ? 'main-content-no-side-bar': ''"
    style="height: 100vh;width: 100vw;">
    <template
      v-if="loading">
      <loading-icon
        :size="'medium'"
        :position="'center'" />
    </template>
    <template
      v-else>
      <sidebar-nav
        v-if="!hideNavigation" />
      <main
        class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <!-- Navbar -->
        <nav
          v-if="!hideNavigation"
          id="navbarBlur"
          class="d-none d-xl-block navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl">
          <div class="container-fluid py-1 px-3">
            <div
              id="navbar"
              class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4">
              <div class="ms-md-auto pe-md-3 d-flex align-items-center">
              </div>
              <ul class="navbar-nav  justify-content-end">
                <li class="nav-item d-flex align-items-center">
                  <router-link
                    :to="{ name: 'composePage', params: {} }"
                    class="btn btn-outline-primary btn-sm mb-0 me-3">
                    {{ $tc('menu.compose') }}
                  </router-link>
                </li>
                <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                  <a
                    id="iconNavbarSidenav"
                    href="javascript:;"
                    class="nav-link text-body p-0">
                    <div class="sidenav-toggler-inner">
                      <i class="sidenav-toggler-line" />
                      <i class="sidenav-toggler-line" />
                      <i class="sidenav-toggler-line" />
                    </div>
                  </a>
                </li>
                <li class="nav-item px-3 d-flex align-items-center">
                  <a
                    href="javascript:;"
                    class="nav-link text-body p-0"
                    @click="logout()">
                    <i class="fas fa-sign-out-alt cursor-pointer" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <router-view />
        <footer-general />
      </main>
    </template>
  </section>
</template>

<script>
import Vue from 'vue';
import router from '@/router/router'
import SidebarNav from "@/views/Components/SidebarNav.vue";
import FooterGeneral from "@/views/Components/FooterGeneral.vue";
import store from "@/store/store";
import {getSocialProviders} from "@/apis/FacebookApi";
import LoadingIcon from "@/views/Components/loadingIcon.vue";

export default Vue.extend({
  name: 'App',
  components: {LoadingIcon, SidebarNav, FooterGeneral},
  data: () => ({
    loading: true,
    userLoginInterval: null
  }),
  computed: {
    hideNavigation: function() {
      const publicRoutes = [
        "Callback",
        "Login",
        "LoginView",
        "GoogleGeneration",
        "GoogleCallback",
        "GoogleAuthenticate",
      ]
      if (this.$route?.name && publicRoutes.includes(this.$route?.name)) {
        this.loading = false;
        return true
      } else {
        return false
      }
    }
  },
  beforeDestroy: function () {
    clearInterval(this.userLoginInterval)
  },
  mounted() {
    if (this.$store.getters['socialProvider/getSocialProviders'].length === 0 || this.$store.getters['socialProvider/isExpired']) {
      getSocialProviders().then(res => {
        const socials = res.data.socialProviderList;
        this.loading = false;
        this.$store.dispatch('socialProvider/setSocialProviders', socials)
        this.$store.dispatch('socialProvider/setLoadedAt', new Date().getTime())
        if (socials.length === 0) {
          Vue.toasted.show(this.$tc('loadingErrors.no_social_providers'), { type: 'success' })
          this.$router.push({ name: 'SocialList' })
        }
      }) .catch((error) => {
        this.loading = false;
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
    } else {
      this.loading = false;
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    }
  }
});
</script>
<style lang="scss">
.main-content-no-side-bar {
  width: 100%;
}
</style>
