import axios from 'axios';
import network from "@/apis/Network";

export async function getHashtags(userMessage) {
  let data = JSON.stringify({
        type: "hashtags",
        userMessage: userMessage,
        source : '',
        userTone: ''
  })
  return network.post('/ai/getResult', data)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log(error);
      });
}

export async function generatePost(userMessage, tone) {
    const toneName = tone ? tone.name : "casual"
    let data = JSON.stringify({
        type: "post",
        userMessage: userMessage,
        source : '',
        userTone: toneName
    })
    return network.post('/ai/getResult', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function reformulateReply(userMessage, sourceComment, tone) {
    let data = JSON.stringify({
        type: "reply",
        userMessage: userMessage,
        source : sourceComment,
        userTone: tone
    })
    return network.post('/ai/getResult', data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error);
        });
}
/* for unsplash if added later
export async function frenchtranslate(userMessage) {
  let data = JSON.stringify({
    systemMessage: "translate from french to english:",
    userMessage: userMessage
  })

  return network.post('/getChatResult', data)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error);
    });
}*/

