import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/main.css'
import './assets/base.css'
import '@/assets/scss/soft-ui-dashboard.scss'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toasted from 'vue-toasted'
import './filter.ts';
import VueI18n from 'vue-i18n'
import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
// @ts-ignore
import VueTidio from 'vue-tidio';
// Or, if you have to support IE9
import "@egjs/vue-flicking/dist/flicking-inline.css";
import * as Sentry from "@sentry/vue";
// @ts-ignore
import { Auth0Plugin } from '@/auth';
import french from '@/internationalization/languages/fr'
import english from '@/internationalization/languages/en'

Vue.use(Flicking)
Vue.use(BootstrapVue)

//Auth0 initialization
const domain = 'dev-gfm5ejrg45ejtnrt.us.auth0.com'
const clientId = 'P0XwmPe8bqqyZFTTPkkM9bNFqORQxQmW'
const authorizationParams = {
  audience: 'Monfeed.com/api',
}
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  authorizationParams,
  // @ts-ignore
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
  singleton: true
})
Vue.use(VueI18n)
Vue.use(VueTidio, { appKey: 'wcbfzsasm5uptq7eq8oywo2rorstrju3' });



const fallbackLanguage = 'fr'
const browserLanguage = 'fr'
export const i18n = new VueI18n({
  locale: browserLanguage,
  fallbackLocale: fallbackLanguage,
  messages: { fr: french , en: english }
})
//Sentry initialization
Sentry.init({
  Vue,
  dsn: "https://6238c8bea5e95f6193cd5de5515f0107@o4505630496849920.ingest.sentry.io/4505630499209216",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')



