<template>
  <div class="card h-100">
    <div
      class="card-body"
      style="text-align: center">
      <div class="d-flex">
        <img
          v-if="social.socialBrand === 'facebook'"
          class="social_icons"
          alt="facebook"
          src="../../assets/img/fb.png">
        <img
          v-else-if="social.socialBrand === 'instagram'"
          class="social_icons"
          alt="instagram"
          src="../../assets/img/ig.png">
        <b-dropdown
          class="dropdown-box"
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          style="position: absolute;right: -5px;">
          <template #button-content>
            <i
              class="fas fa-ellipsis-v"
              aria-hidden="true"
              style="color: #8392ab" />
          </template>
          <b-dropdown-item
            @click="refresh()">
            <i
              class="fas fa-sync me-2"
              style="color: #6aabfa" />
            {{ $tc('connectSocial.actions.refresh') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="remove()">
            <i
              class="fas fa-trash-alt me-2"
              style="color: #ef8ea5" />
            {{ $tc('connectSocial.actions.remove') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="avatar avatar-xl position-relative mt-3">
        <img
          :src="social.profileAvatar"
          alt="profile_image"
          class="w-100 border-radius-lg shadow-sm">
      </div>
      <p
        class="mb-0 mt-2 font-weight-bold">
        {{ social.profileName }}
      </p>
      <span
        class="text-xs">
        {{ $tc("connectSocial.socialAccounts.added") }} • {{ social.createdAt | dateToDateString }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialBox",
  props: {
    social: {
      type: Object,
      default: null
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh', this.social);
    },
    remove() {
      this.$emit('remove', this.social);
    }
  }
};
</script>

<style>
.dropdown-box button{
  padding: 0 10px !important;
}
.social_icons{
  width: 30px;
  height: 30px;
}
</style>