<template>
  <div
    class="card card-body blur shadow-blur mx-4 mb-2 overflow-hidden cursor-pointer"
    @click="$emit('connect')">
    <div class="row gx-4">
      <div class="col-auto">
        <div class="avatar avatar-xl position-relative">
          <img
            v-if="img === 'facebook'"
            alt=""
            class="w-100 border-radius-lg shadow-sm"
            src="/src/assets/img/facebook_outline.png">
          <img
              v-if="img === 'instagram'"
              alt=""
              class="w-100 border-radius-lg shadow-sm"
              src="/src/assets/img/instagram_outline.png">
        </div>
      </div>
      <div class="col-auto my-auto">
        <div class="h-100">
          <h5 class="mb-1">
            {{ title }}
          </h5>
          <p class="mb-0 font-weight-bold text-sm">
            {{ subtitle }}
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
        <div class="nav-wrapper position-relative end-0">
          <ul class="nav nav-pills nav-fill p-1 bg-transparent">
            <li class="nav-item">
              <a class="nav-link">
                <i
                  style="float: right;font-size:20px"
                  class="fas fa-sign-in-alt" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalBrand",
  props: {
    img: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.border-radius-lg {
    border-radius: 0.75rem;
}
</style>