<template>
  <div class="container-fluid py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 m-auto">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">Sélectionnez le(s) compte(s) à auditer :</h6>
                <img
                  class="img-google mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
                  src="../../assets/img/Google_Ads_logo.svg.png"
                  alt="Google Logo">
              </div>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <template
                  v-for="(managerGroup, index) in clientList">
                  <div :key="index" class="col-md-12">
                    <!-- Display the manager's name (Assuming the first entry is the manager) -->
                    <h5>Manager: {{ managerGroup[0].client.customer.descriptive_name }}</h5>
                    <!-- Loop over the inner array (each client of the manager) -->
                    <ul>
                      <li
                        v-for="(clientEntry, clientIndex) in managerGroup"
                        :key="clientIndex"
                        class="list-group">
                        <div class="form-check form-switch ps-0">
                          <input
                            :id="'flexSwitchCheckDefault'+clientIndex+index"
                            v-model="selectedClient"
                            :value="clientEntry"
                            name="flexSwitchCheckDefault"
                            class="ms-auto form-check-input"
                            type="radio">
                          <label
                            class="form-check-label ms-3 text-truncate w-80 mb-0"
                            :for="'flexSwitchCheckDefault'+clientIndex+index">
                            {{ clientEntry.clientName }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </template>
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm mb-0 col-4 ms-auto"
                    @click="generateKeywords">
                  Choose
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {generateKeywords, generatePdf, getClientList} from "@/apis/GoogleApi";
import Vue from "vue";

export default {
  name:"GoogleCallback",
  data() {
    return {
      clientList: [],
      accessToken: '',
      selectedClient: null,
      accountId: ''
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.accessToken = urlParams.get('code');
    this.loading = true;
    getClientList(this.accessToken).then((response) => {
      this.clientList = response.data.customers;
      this.accountId = response.data.id;
      this.loading = false;
    }).catch((error) => {
      this.$router.push({ name: 'GoogleAuthenticate' });
    });
  },
  methods: {
    generateKeywords() {
      this.loading = true;
      const accountId = this.accountId;
      const clientName = this.selectedClient.clientName;
      const clientId = this.selectedClient.client.customer_client_link.client_customer.split('/')[1];
      const managerName = this.selectedClient.client.customer.descriptive_name;
      const managerId = this.selectedClient.client.customer.resource_name.split('/')[1];
      generateKeywords(accountId, clientId, clientName, managerName, managerId).then((response) => {
        generatePdf(response.data.reportId).then(async (response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          this.loading = false;
          link.click();
        }).catch((error) => {
          this.loading = false;
          console.log(error);
          Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
        });
      }).catch((error) => {
        this.loading = false;
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
    }
  }
}
</script>
<style scoped>
.form-check-input:checked[type=checkbox], .form-check-input:checked[type=radio] {
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.img-google {
  width: 50px;
  height: auto;
  margin-bottom: 20px;
}
</style>
