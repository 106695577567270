<template>
  <section class="col-8 m-auto">
    <div class="card card-plain mb-4">
      <div class="card-body p-3">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex flex-column h-100">
              <h2 class="font-weight-bolder mb-0">
                {{ $tc('connectSocial.brandSelection.instagram') }}
              </h2>
              <p class="mb-0 font-weight-bold text-sm"
                v-html="$tc('connectSocial.brandSelection.instagramSubtitle1')">
              </p>
              <p class="mb-0 font-weight-bold text-sm">
                {{ $tc('connectSocial.brandSelection.instagramSubtitle2') }}
              </p>
              <h6
                class="mb-0 font-weight-bold link-help mt-3"
                @click="howToLinkIg()">
                <i class="fas fa-info-circle"></i>
                {{ $tc('connectSocial.brandSelection.instagramSubtitle3') }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <horizontal-brand
        :img="'/src/assets/img/facebook_outline.png'"
        :title="'Facebook Page'"
        :subtitle="$tc('connectSocial.brandSelection.facebook')"
        @connect="connect('instagram')" />
  </section>
</template>

<script>
import HorizontalBrand from "@/views/SocialConnect/HorizontalBrand.vue";

export default {
  name: "InstagramPage",
  components: { HorizontalBrand },
  methods: {
    connect(social) {
      this.$router.push({ name: 'FacebookAuthenticate', params: { brand: social }});
    },
    howToLinkIg() {
      window.open('http://www.google.com', '_blank', 'noreferrer');
    }
  }
};
</script>

<style lang="scss">
.link-help {
  background: -webkit-linear-gradient(#2152ff, #02c6f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  svg{
    color: #227cfb !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
}
</style>