import axios from 'axios';
import network from "@/apis/Network";

export async function uploadMedia(formData) {
    return network.post('/media/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: 150000,
        baseURL: 'https://openai-qv20.onrender.com/web/openai'
    })
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        console.log(error)
    });
}
export async function deleteMedia(mediaId) {
    const data = {
        mediaId: mediaId
    }
    return network.post('/media/deleteMedia', data)
        .then((response) => {
            return response
        })
}
