import axios from 'axios';
import network from './Network'

export async function getPagesList(accessToken) {
  const url = `https://graph.facebook.com/v16.0/me?fields=accounts{picture{url,height},name}&access_token=${accessToken}`;
  let config = {
    method: 'get',
    url: url
  }
  return axios.request(config)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function connectFacebook(accessToken, pageId, socialBrand, refresh) {
  let data = JSON.stringify({
    accessToken: accessToken,
    pageId: pageId,
    socialBrand: socialBrand,
    refresh: refresh
  })

  return network.post('/social/connectFacebook', data)
    .then((response) => {
      return response
    })
}
export async function connectInstagram(accessToken, pageId, socialBrand, refresh) {
  let data = JSON.stringify({
    accessToken: accessToken,
    pageId: pageId,
    socialBrand: socialBrand,
    refresh: refresh
  })

  return network.post('/social/connectInstagram', data)
    .then((response) => {
      return response
    })
}
export async function getSocialProviders() {
  return network.post('/social/getSocialProviders')
    .then((response) => {
      return response
    })
}
export async function getFeed() {
  return network.post('/social/getFeed')
    .then((response) => {
      return response
    })
}
export async function getPost(id) {
  let data = JSON.stringify({
    id: id
  })
  return network.post('/post/get', data)
    .then((response) => {
      return response
    })
}
export async function deletePost(id) {
  let data = JSON.stringify({
    id: id
  })
  return network.post('/post/delete', data)
    .then((response) => {
      return response
    })
}

export async function deleteSocialProvider(providerId) {
  let data = JSON.stringify({
    providerId: providerId
  })
  return network.post('/social/deleteSocialProvider', data)
    .then((response) => {
      return response
    })
}
export async function publishPost(post) {
  let data = JSON.stringify(post)
  return network.post('/post/publishPost', post)
    .then((response) => {
      return response
    })
}
