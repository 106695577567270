<template>
  <div
    class="card card-body blur shadow-blur mx-4 mb-2 overflow-hidden cursor-pointer"
    @click="newSocialProvider()">
    <div class="row gx-4">
      <div class="col-auto">
        <div class="avatar avatar-xl position-relative">
          <img
            alt=""
            class="w-100 border-radius-lg shadow-sm"
            :src="account.picture.data.url">
        </div>
      </div>
      <div class="col-auto my-auto">
        <div class="h-100">
          <h5 class="mb-1">
            {{ account.name }}
          </h5>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
        <div class="nav-wrapper position-relative end-0">
          <ul class="nav nav-pills nav-fill p-1 bg-transparent">
            <li class="nav-item">
              <a class="nav-link">
                <i
                  style="float: right;font-size:20px"
                  class="fas fa-sign-in-alt" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { connectFacebook, connectInstagram } from "@/apis/FacebookApi";
import Vue from 'vue'

export default {
  name: "HorizontalPage",
  props: {
    account: {
      type: Object,
      default: null
    },
    token: {
      type: String,
      default: null
    },
    brand: {
      type: String,
      default: null
    }
  },
  methods: {
    newSocialProvider() {
      this.$emit('isLoading')
      if (this.brand === 'facebook') {
        connectFacebook(this.token, this.account.id,  'facebook', false)
          .then(res => {
            this.$router.push({ name: 'SocialList' })
            Vue.toasted.show(this.$tc('savingSuccess.social_provider_connected'), { type: 'success' })
          }).catch(e => {
          this.$router.push({ name: 'BrandSelection' })
          console.log(e)
          if (e.response.status === 500) {
            Vue.toasted.show(this.$tc(`loadingErrors.${e.response.data.message}`), { type: 'error' })
          } else {
            Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
          }
        })
      }
      else if (this.brand === 'instagram') {
        connectInstagram(this.token, this.account.id, 'instagram', false)
          .then(res => {
            this.$router.push({ name: 'SocialList' })
            Vue.toasted.show(this.$tc('savingSuccess.social_provider_connected'), { type: 'success' })
          }).catch(e => {
            this.$router.push({ name: 'BrandSelection' })
            console.log(e)
          if (e.response.status === 500) {
            Vue.toasted.show(this.$tc(`loadingErrors.${e.response.data.message}`), { type: 'error' })
          } else {
            Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
          }
        })
      }
    }
  }
};
</script>

<style scoped>
.border-radius-lg {
    border-radius: 0.75rem;
}
</style>