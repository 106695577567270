export default {
    namespaced: true,
    state: {
        loadedAt: 0,
        socialProviders: []
    },
    getters: {
        getSocialProviders: (state: { socialProviders: any }) => {
            return state.socialProviders
        },
        isExpired: (state: { loadedAt: null }) => {
            const now = new Date().getTime()
            return state.loadedAt ? now > state.loadedAt + 3600000: false;
        }
    },
    mutations: {
        setSocialProviders: (state: { socialProviders: any }, value: any) => {
            state.socialProviders = value
        },
        setLoadedAt: (state: { loadedAt: any }, value: any) => {
            state.loadedAt = value
        }
    },
    actions: {
        setSocialProviders: ({ commit }: any, value: any) => {
            commit('setSocialProviders', value)
        },
        setLoadedAt({ commit }: any, value: any) {
            commit('setLoadedAt', value)
        }
    }
}
