import network from './Network'

export async function reformulateComment(comment, tone) {
  const data = {
    comment: comment,
    tone: tone
  }
  return network.post('/comment/reformulate', data)
    .then((response) => {
      return response
    })
}
export async function getUnAnswered() {
  return network.get('/comment/getUnAnswered')
    .then((response) => {
      return response
    })
}
export async function getNewComments() {
  return network.get('/comment/getNewComments')
    .then((response) => {
      return response
    })
}
export async function answer(commentId, message) {
  const data = {
    commentId: commentId,
    message: message
  }
  return network.post('/comment/answer', data)
    .then((response) => {
      return response
    })
}
export async function markasAnswered(commentId) {
  const data = {
    id: commentId
  }
  return network.post('/comment/markasAnswered', data)
    .then((response) => {
      return response
    })
}