<template>
  <section class="col-8 m-auto">
    <loading-icon
      v-if="loading"
      :size="'medium'"
      :position="'center'" />
    <template v-else >
      <div class="card card-plain">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-column h-100">
                <h2 class="font-weight-bolder mb-0">
                  {{ $tc('connectSocial.accountSelection.title') }}
                </h2>
                <p class="mb-0 font-weight-bold text-sm">
                  {{ $tc('connectSocial.accountSelection.subtitle') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="account in accounts"
        :key="account.id">
        <br>
        <horizontal-page
          :account="account"
          :token="token"
          :brand="brand"
          @isLoading="isLoading"/>
      </div>
    </template>
  </section>
</template>

<script>
import { getPagesList } from "@/apis/FacebookApi";
import HorizontalPage from "@/views/SocialConnect/HorizontalPage.vue";
import LoadingIcon from "@/views/Components/loadingIcon.vue";

export default {
  name: "PageChoice",
  components: { LoadingIcon, HorizontalPage },
  props: {
    token: {
      type: String,
      default: null
    },
    brand: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      accounts: [],
      loading: false
    }
  },
  mounted() {
    if (this.token === null || this.token === '') {
      this.$router.push({ name: 'BrandSelection' })
    }
    getPagesList(this.token).then(res => {
      this.accounts = res.accounts.data
    })
  },
  methods: {
    isLoading() {
      this.loading = true
    }
  }
};
</script>

<style scoped>

</style>