<template>
  <section>
    <loading-icon
      v-if="loadingAction"
      :size="'medium'"
      :position="'center'"
      :margin="''"
      class="ai-loader" />
    <div
      class="p-2 comment mb-2"
      :class="[active ? 'active-comment' : '',loadingAction ? 'feed-card-loading' : '']">
      <div class="d-flex">
        <div class="flex-shrink-0">
          <img
            alt="Image placeholder"
            class="avatar-sm rounded-circle fb-avatar"
            src="@/assets/imagesThemes/apple-icon.png">
        </div>
        <div class="flex-grow-1 ms-2 row">
          <div class="col-8">
            <h6 class="mb-0 mt-1">
              {{ comment.from }}
            </h6>
            <p class="text-sm mb-2">
              {{ comment.message }}
            </p>
            <div class="d-flex">
              <div>
                <i class="ni ni-like-2 me-1 cursor-pointer" />
              </div>
              <span class="text-xs me-2">3m</span>
              <div>
                <i class="ni ni-curved-next me-1 cursor-pointer" />
              </div>
            </div>
          </div>
          <div class="col-4">
            <b-button
              v-if="!comment.isAnswered"
              type="button"
              class="btn btn-outline-blue float-end"
              @click="markAsAnswered()">
              <i class="fas fa-check" />
              {{ $tc('comment.mark_as_answered') }}
            </b-button>
          </div>
        </div>
      </div>
      <div class="d-flex col-11 offset-1 mt-1">
        <div
          v-if="!comment.isAnswered || (comment.isAnswered && comment.answer !== '')"
          class="flex-shrink-0">
          <img
            alt="Image placeholder"
            class="avatar-sm rounded-circle me-3"
            :src="social ? social.profileAvatar : ''">
        </div>
        <template v-if="!comment.isAnswered">
          <div class="flex-grow-1 my-auto">
            <form>
              <b-form-textarea
                id="post-input"
                v-model="response"
                :placeholder="$tc('comment.textarea_placeholder')"
                rows="1"
                no-resize
                :disabled="loadingAction"
                class="bg-light form-control" />
              <p
                v-if="response === '' && validating"
                class="text-error">
                {{ $tc('compose.errors.comment_empty') }}
              </p>
              <b-button
                v-b-tooltip.hover
                :title="$tc('compose.tooltip_emoji')"
                variant="link"
                class="text-dark pb-0 px-2"
                :disabled="loadingAction"
                @click.stop="setEmojiPicker()">
                <font-awesome-icon
                  class="icon-footer"
                  :icon="['far', 'smile']" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$tc('compose.tooltip_template')"
                variant="link"
                class="text-dark pb-0 px-2"
                :disabled="loadingAction"
                @click="$emit('open-templates', comment._id)">
                <i class="icon-footer fas fa-list-ul"></i>
              </b-button>
              <v-emoji-picker
                v-show="isEmojiPickerVisible"
                ref="emojiPicker"
                class="emoji-picker"
                emojis-by-row="8"
                emoji-size="24"
                @click.stop
                @select="selectEmoji" />
              <b-button
                type="button"
                variant="primary"
                class="btn bg-gradient-primary  mt-1 mx-2 float-end"
                @click="reformulate()">
                <i class="fas fa-magic me-1"></i>
                {{ $tc('comment.reformulate') }}
              </b-button>
              <b-dropdown
                id="tone-dropdown"
                class="btn btn-outline-primary btn-primary  mt-1 mx-2 float-end">
                <template
                  #button-content>
                  <template
                    v-if="selectedTone">
                    <img
                      v-if="selectedTone.name === 'casual'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/casual.png">
                    <img
                      v-if="selectedTone.name === 'formal'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/formal.png">
                    <img
                      v-if="selectedTone.name === 'friendly'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/friendly.png">
                    <img
                      v-if="selectedTone.name === 'exclusive'"
                      class="tone-emoji"
                      :alt="selectedTone.name"
                      src="@/assets/img/exclusive.png">
                    {{ $tc(`comment.tone.${selectedTone.name}`) }}
                  </template>
                  <template v-else>
                    <img
                      class="tone-emoji"
                      alt="casual"
                      src="@/assets/img/casual.png">
                    {{ $tc('comment.tone.select') }}
                  </template>
                </template>
                <b-dropdown-item
                  v-for="tone in tonesList"
                  :key="tone.name">
                  <div
                    @click="selectedTone = tone">
                    <img
                      v-if="tone.name === 'casual'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/casual.png">
                    <img
                      v-if="tone.name === 'formal'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/formal.png">
                    <img
                      v-if="tone.name === 'friendly'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/friendly.png">
                    <img
                      v-if="tone.name === 'exclusive'"
                      class="tone-emoji"
                      :alt="tone.name"
                      src="@/assets/img/exclusive.png">
                    {{ $tc(`comment.tone.${tone.name}`) }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </form>
          </div>
          <div class="flex-shrink-0">
            <button
              class="btn btn-primary mb-0 ms-2"
              @click="answer()">
              <i class="fas fa-paper-plane"></i>
            </button>
          </div>
        </template>
        <template v-else-if="comment.answer !== ''">
          <div class="row justify-content-start mb-4">
            <div class="col-auto">
              <div class="card ">
                <div class="card-body py-2 px-3">
                  <p class="mb-1">
                    {{ comment.answer }}
                  </p>
                  <div class="d-flex align-items-center text-sm opacity-6">
                    <i class="ni ni-check-bold text-sm me-1" />
                    <small>{{ comment.answerTime }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
 </template>
  <script>
  import { VEmojiPicker } from 'v-emoji-picker';
  import { faSmile } from '@fortawesome/free-regular-svg-icons'
  import {library} from "@fortawesome/fontawesome-svg-core";
  import { reformulateComment, answer, markasAnswered } from "@/apis/CommentApi";
  import LoadingIcon from "@/views/Components/loadingIcon.vue";
  import Vue from "vue";

  library.add(faSmile)

  export default {
   name: 'CommentPost',
   components: {
     LoadingIcon,
     VEmojiPicker
   },
   props: {
     active: {
       type: Boolean,
       default: false
     },
     comment: {
       type: Object,
       required: true
     },
     social: {
       type: Object,
       required: false
     }
   },
   data() {
     return {
       selectedTone: null,
       isEmojiPickerVisible: false,
       loadingAction: false,
       response: '',
       validating: false,
       tonesList: [
         {
           img: 'casual.png',
            name: 'casual'
         },
         {
           img: 'formal.png',
            name: 'formal'
         },
         {
           img: 'friendly.png',
            name: 'friendly'
         },
         {
           img: 'exclusive.png',
            name: 'exclusive'
         }
       ]
     }
   },
   mounted() {
     document.addEventListener('click', this.checkOutsideClick);
   },
   beforeDestroy() {
     document.removeEventListener('click', this.checkOutsideClick);
   },
   methods: {
    answer() {
      this.validating = true;
      if (this.response !== '') {
       this.loadingAction = true;
       answer(this.comment._id, this.response)
         .then((response) => {
          this.comment.answer = this.response
          this.comment.isAnswered = true;
          this.loadingAction = false;
          this.validating = false;
         })
         .catch(() => {
           this.loadingAction = false;
           this.validating = false;
           Vue.toasted.show(this.$tc('comment.reformulate_error'), { type: 'error' })
         });
      }
     },
     reformulate() {
      this.validating = true;
      if (this.response !== '') {
      this.loadingAction = true;
      reformulateComment(this.response, this.selectedTone ? this.selectedTone.name : 'casual')
        .then((response) => {
          this.response = response.data.aiCall;
          this.loadingAction = false;
          this.validating = false;
        })
        .catch(() => {
          this.loadingAction = false;
          this.validating = false;
          Vue.toasted.show(this.$tc('comment.reformulate_error'), { type: 'error' })
        });
      }
     },
     selectTemplate(template) {
       this.response += template;
     },
     setEmojiPicker() {
       this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
     },
     checkOutsideClick(event) {
       const emojiPicker = this.$refs.emojiPicker;
       if (emojiPicker && !emojiPicker.$el.contains(event.target)) {
         this.isEmojiPickerVisible = false;
       }
     },
     selectEmoji(emoji) {
       this.response = this.response + emoji.data;
     },
     markAsAnswered() {
      this.loadingAction = true;
      markasAnswered(this.comment._id).then((response) => {
        this.comment.isAnswered = true;
        this.comment.answer = '';
        this.loadingAction = false;
      })
      .catch(() => {
        this.loadingAction = false;
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), { type: 'error' })
      });
     }
   }
  }
  </script>
  <style lang="scss">
  .comment {
   border: 1px solid #c1c1c1;
   border-radius: 8px;
   &.active-comment {
     background-color: rgb(225, 230, 255);
   }
  }
  .avatar-owner {
   border-radius: 50%;
   width: 36px;
   height: 36px;
   line-height: 37px;
   text-align: center;
  }
  .fb-avatar {
   height: 35px;
   width: 35px;
   border-radius: 50%;
   overflow: hidden;
   z-index: 2;
  }
  #tone-dropdown button{
   padding: 0px !important;
   box-shadow: none;
   text-transform: uppercase !important;
   background: transparent !important;
   color: #2d7dd2 !important;
  }
  .tone-emoji {
   width: 20px;
   height: 20px;
   margin-right: 5px;
  }
  .feed-card-loading::after {
    border-radius: 15px;
    content: "";
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  </style>