export default {
    menu: {
        dashboard: 'Tableau de bord',
        compose: 'Nouvelle publication',
        calendar: 'Calendrier',
        posts: 'Publications',
        engagements: 'Engagement',
        socials: 'Comptes Sociaux',
        settings: 'Paramètres',
    },
    login: {
        signInMessage: 'Connectez-vous à votre compte',
        cta: 'Se connecter',
        email: 'Email',
        password: 'Mot de passe',
        rememberMe: 'Se souvenir de moi',
        signInText1: 'En vous connectant, vous acceptez',
        terms: 'nos conditions d\'utilisation',
        and: 'et notre',
        privacy: 'politique de confidentialité.'
    },
    analytics: {
        reach: 'Portée',
        commentdelay: 'Délai de réponse',
        impressions: 'Impressions',
        posts: 'Publications',
        engagement_rate: 'Taux d\'engagement',
        new_followers: 'Nouveaux Abonnés',
        comments: 'Commentaires',
        best_posts: {
            title: 'Meilleures Publications',
            engagement_rate: 'Taux d\'engagement',
            reach: 'Portée',
            impressions: 'Impressions',
            caption: 'Légende',
            best_day: 'Meilleur jour',
            best_etiquette: 'Meilleur tag',
            best_type_of_post: 'Meilleur type'
        },
        comments_card: {
            title: 'Analyse de sentiment des commentaires',
            negative: 'Commentaires Négatifs',
            neutral: 'Commentaires Neutres',
            positive: 'Commentaires Positifs',
            tooltip: 'Ceci est une analyse de sentiment approximative, l\'IA est toujours en apprentissage et l\'analyse s\'améliorera avec le temps.'
        },
        more_than: 'de plus que',
        less_than: 'de moins que'
    },
    setting: {
        password: {
            wrongPassword: 'Mot de passe incorrect',
            success: 'Mot de passe mis à jour avec succès',
            changePassword: 'Changer le mot de passe',
            oldPassword: 'Mot de passe actuel',
            newPassword: 'Nouveau mot de passe',
            confirmPassword: 'Confirmer le nouveau mot de passe',
            updatePassword: 'Mettre à jour le mot de passe',
            errorPasswordLength: 'Le mot de passe doit contenir au moins 8 caractères',
            errorLowercaseLength: 'Le mot de passe doit contenir au moins 1 lettre majuscule',
            errorUppercaseLength: 'Le mot de passe doit contenir au moins 1 lettre minuscule',
            errorSpecialCharacter: 'Le mot de passe doit contenir au moins 1 caractère spécial',
            errorContainNumber: 'Le mot de passe doit contenir au moins 1 chiffre',
            errorMustBeSame: 'Les mots de passe doivent être identiques',
        },
        delete_account: {
            confirm: 'Confirmer',
            checkbox: 'Je confirme que je souhaite supprimer mon compte',
            title: 'Supprimer le compte',
            subtitle: 'Voulez-vous vraiment supprimer votre compte ?',
            save: 'Supprimer le compte',
        },
    },
    compose: {
        thumbnail_title: 'Choisir l\'aperçu du reel',
        schedule_title: 'Planifier la publication',
        title: 'Créer une publication',
        subtitle: 'Créez une publication performante pour faire passer votre message.',
        textarea_placeholder: 'Que souhaitez-vous partager ?',
        tooltip_upload: 'Ajouter une image',
        tooltip_emoji: 'Ajouter un emoji',
        tooltip_hashtag: 'generer un hashtag avec l\'IA',
        tooltip_template: 'Choisir un modèle',
        rewriteBtn : 'Générer avec l\'IA',
        schedule_publish: 'Planifier',
        instant_publish: 'Publier maintenant',
        time_to_post: 'Meilleures heures pour publier',
        time_selection: 'Choisir l\'heure de publication',
        preview_title: 'Aperçus de la publication',
        preview_subtitle: 'Aperçu sur les réseaux sociaux',
        preview_missing: 'Aucun aperçu disponible',
        scheduled_for: {
            scheduled: 'Programmé pour',
            finalized: 'Programmé pour',
            published: 'Publié le',
        },
        image_upload: {
            title: 'Ajouter une image',
            upload_msg: 'clickez ou Glissez et déposez une image',
            error_format: 'Format non supporté',
            search: 'Rechercher une image',
            error_max: 'Un seul fichier maximum',
            from_unsplash: 'Depuis unsplash',
            by: 'par',
            use_image: 'Utiliser',
            load_more: 'Charger plus',
            upload: 'Télécharger',
            stock: 'Photos gratuites',
            crop: 'Recadrer',
            crop_title: 'Recadrer l\'image',
        },
        labels : {
            label: 'Tags',
            title: 'Ajouter des tags',
            cancel: 'Retour',
            create: 'Créer',
            update: 'Mettre à jour',
            placeholder: 'Titre du tag',
            select_placeholder: 'Selectionner des tags',
            selected_label: 'Selectionnée',
            edit_labels_tooltip: 'Editer les tags',
            new_tag: 'Nouveau tag',
            color: 'Couleur',
        },
        publish_type: {
            IMAGE: 'Image',
            VIDEO: 'Video',
            CAROUSEL: 'Carousel',
            TEXT: 'Text'
        },
        publish_type_creation: {
            reels: 'Reels',
            story: 'Story',
            post: 'Post',
        },
        errors: {
            comment_empty: 'Vous devez ajouter une réponse',
            too_short: 'Le texte est un peu trop court. Essayez avec un texte plus long pour que nous puissions faire une reformulation plus précise.',
            ratio_title : 'Instagram Stories use 9:16 media',
            ratio_text: 'So if  you use different aspect ratio, your media could be cut or resized. You can resize your images by selecting our edit functionality above. This functionality is not available for videos.',
            ig_image_required: 'Vous devez télécharger au moins une image pour publier sur Instagram',
            channel_required: 'Vous devez sélectionner au moins un compte sur lequel publier',
            caption_required: 'la légende est obligatoire',
            video_once_error: 'Un Post ne peux contenir qu\'une seule vidéo.\n si vous souhaitez télécharger cette vidéo, veuillez d\'abord supprimer tous les médias.',
            media_2207032: 'Échec de la création d\'un conteneur média. Réessayez',
            media_2207053: 'Une erreur inconnue s\'est produite lors du téléchargement. Générez un nouveau conteneur et utilisez-le pour réessayer. Cela ne concerne que les téléchargements vidéo.',
            media_2207027: 'Vérifiez le statut du conteneur et publiez lorsque son statut est "TERMINÉ".',
            media_2207026: 'Format vidéo non pris en charge. Conseillez à l\'utilisateur de l\'application de télécharger un fichier MOV ou MP4 (MPEG-4 Part 14). Veuillez consulter les spécifications vidéo.',
            media_2207008: 'Erreur temporaire lors de la publication d\'un conteneur. Réessayez 1 à 2 fois dans les 30 prochaines secondes à 2 minutes. Si cela échoue, générez un nouvel identifiant de conteneur et utilisez-le pour réessayer.',
            media_2207006: 'Erreur de permission possible en raison d\'une autorisation manquante ou d\'un jeton expiré. Générez un nouveau conteneur et utilisez-le pour réessayer.',
            media_2207042: 'L\'utilisateur de l\'application a atteint sa limite quotidienne de publication. Conseillez à l\'utilisateur de l\'application de réessayer le lendemain.',
            media_2207051: 'L\'action de publication est suspectée d\'être du spam. Nous restreignons certaines activités pour protéger notre communauté. Faites-nous savoir si vous pouvez déterminer que les actions de publication ne sont pas du spam.',
            media_2207057: 'L\'offset de vignette que vous avez entré est hors des limites de la durée de la vidéo. Ajoutez l\'offset correct en millisecondes.',
            media_2207003: 'Le téléchargement du média prend trop de temps. Réessayez.',
            media_2207020: 'Le média que vous essayez d\'accéder a expiré. Veuillez réessayer en générant un nouvel ID de conteneur.',
            media_2207001: 'Erreur serveur Instagram. Réessayez.',
            media_2207023: 'Le type de média {media-type} est inconnu.',
            media_2207028: 'Votre publication ne peut pas être utilisée en tant que carrousel. Les carrousels doivent comporter au moins 2 photos/vidéos et au maximum 10 photos/vidéos.',
            media_2207035: 'Les positions des étiquettes de produits ne doivent pas être spécifiées pour les médias vidéo.',
            media_2207036: 'Les positions des étiquettes de produits sont requises pour les médias photo.',
            media_2207037: 'Nous n\'avons pas pu ajouter toutes les étiquettes de produits. L\'identifiant du produit peut être incorrect, le produit peut être supprimé ou vous pouvez ne pas avoir l\'autorisation pour taguer le produit.',
            media_2207040: 'Vous ne pouvez pas utiliser plus de {max-tag-count} étiquettes par média créé.',
            media_2207050: 'Le compte Instagram est restreint.',
            media_2207052: 'Impossible de récupérer le média depuis cette URI : {uri}.',
            media_2207004: 'L\'image est trop volumineuse pour être téléchargée. Elle doit faire moins de 5MB.',
            media_2207005: 'Le format d\'image n\'est pas pris en charge. Les formats pris en charge sont : {format}.',
            media_2207009: 'L\'image soumise avec un rapport d\'aspect de submitted-ratio ne peut pas être publiée. Veuillez soumettre une image avec un rapport d\'aspect valide.',
            media_2207010: 'La légende de l\'image soumise comporte submitted-caption-length caractères. Le nombre maximum de caractères autorisés pour une légende est de maximum-caption-length. Veuillez soumettre un média avec une légende plus courte.',
            schedule_required: 'Vous devez sélectionner une date de publication',
            schedule_past: 'Vous ne pouvez pas planifier une publication dans le passé',
            video_too_large: 'Fichier trop volumineux, vous pouvez télécharger des fichiers jusqu\'à 50 M',
        }
    },
    comment: {
        title: 'Publications nécessitant une réponse',
        textarea_placeholder: 'Votre réponse',
        tone: {
            select: 'Selectionner un ton',
            casual: 'Ton décontracté',
            formal: 'Ton formel',
            friendly: 'Ton amical',
            exclusive: 'Ton exclusif'
        },
        reformulate: 'Reformuler',
        mark_as_answered: 'Marquer comme répondu',
        reformulate_error: 'Impossible de reformuler le commentaire, veuillez réessayer plus tard',
        template : {
            title: 'Ajouter des modèles',
            cancel: 'Retour',
            create: 'Créer',
            use: 'Utilisé le modèle',
            placeholder_title: 'Titre',
            placeholder_message: 'Messsage',
        },
        no_new_message: 'Aucun commentaire nécessitant une réponse',
    },
    calendar: {
        status: {
            draft: 'Brouillon',
            scheduled: 'Programmée',
            imported: 'Importée',
            published: 'Publiée',
            finalized: 'Finalisée'
        },
        week: 'Semaine',
        month: 'Mois',
        update: 'Mettre à jour la publication',
        delete: 'Supprimer la publication',
    },
    connectSocial: {
        brandSelection: {
            title: 'Ajouter un compte',
            subtitle: 'Ajoutez un compte social pour commencer à publier.',
            facebook: 'Connectez une nouvelle Page Facebook',
            instagram: 'Connectez un nouveau profil Instagram Business',
            instagramSubtitle1: 'Votre compte Instagram doit être connecté à une page Facebook.',
            instagramSubtitle2: 'Connectez-vous avec Facebook ci-dessous et ajoutez la page connectée à votre compte Instagram.',
            instagramSubtitle3: 'Apprenez comment connecter un compte Instagram avec une page Facebook',
        },
        socialAccounts: {
            title: 'Comptes Sociaux',
            subtitle: 'Aperçu de tous les réseaux sociaux gérés par Monfeed.com',
            new: 'Ajouter un compte',
            added: 'Ajouté le'
        },
        accountSelection: {
            title: 'Sélectionnez un compte',
            subtitle: 'Sélectionnez un compte à connecter',
        },
        actions: {
            remove: 'Supprimer le compte',
            refresh: 'Actualiser le compte',
        }
    },
    previewPost: {
      xHoursAgo: 'Il y a {hours} heures',
    },
    confirmationModal: {
        postDeletion: 'Êtes-vous sûr de vouloir supprimer cette publication ?',
        imageDeletion: 'Êtes-vous sûr de vouloir supprimer cette image ?',
        socialDeletion: 'Êtes-vous sûr de vouloir supprimer ce compte social ?',
        cancel: 'Annuler',
        confirm: 'Confirmer',
    },
    loadingErrors: {
        analyticsError: 'Certaines statistiques n\'ont pas pu être récupérées, essayez plus tard',
        no_social_providers: 'Ajoutez votre premier compte social pour utiliser Monfeed.com',
        servererror: 'Something went wrong please try again later',
        no_social_provider_found: 'Account not found please try again',
        social_provider_existent: 'Compte déjà connecté',
        social_provider_existent_other: 'Ce compte est déjà connecté à un autre utilisateur',
        no_page_found_for_refresh: 'No account found for refresh, please delete and try connect again',
        no_instagram_found: 'No Business Instagram account linked to this page',
        video_format: 'Video format not supported, only mp4 and mov are supported',
        best_hours_error: 'Impossible de récupérer les meilleures heures pour publier, veuillez réessayer plus tard',
    },
    savingSuccess: {
        social_provider_connected: 'Compte synchronisé avec succès',
        social_provider_deleted: 'Compte supprimé avec succès',
        post_published: 'Publication réussie avec succès.',
        post_scheduled: 'Publication programmée avec succès.'
    },
    generic: {
        save: 'Enregistrer',
        cancel: 'Annuler',
        days: {
            monday: 'Lundi',
            tuesday: 'Mardi',
            wednesday: 'Mercredi',
            thursday: 'Jeudi',
            friday: 'Vendredi',
            saturday: 'Samedi',
            sunday: 'Dimanche'
        },
        months: {
            january: 'Janvier',
            february: 'Février',
            march: 'Mars',
            april: 'Avril',
            may: 'Mai',
            june: 'Juin',
            july: 'Juillet',
            august: 'Août',
            september: 'Septembre',
            october: 'Octobre',
            november: 'Novembre',
            december: 'Décembre'
        },
    }
}