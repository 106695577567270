import axios from 'axios';
import network from './Network'

export async function getTemplates() {
  return network.post('/template/list')
    .then((response) => {
      return response
    })
}
export async function createTemplate(title, comment) {
  const data = {
    comment: comment,
    title: title,
    socialProviderId: 1
  }
  return network.post('/template/create', data)
    .then((response) => {
      return response
    })
}
export async function deleteTemplate(id) {
  const data = {
    templateId: id
  }
  return network.post('/template/delete', data)
    .then((response) => {
      return response
    })
}