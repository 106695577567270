import axios from 'axios';
import network from './Network'

export async function getLabels() {
    return network.post('/label/getLabels')
        .then((response) => {
            return response
        })
}
export async function createLabel(title, color) {
    const data = {
        color: color,
        title: title
    }
    return network.post('/label/createLabel', data)
        .then((response) => {
            return response
        })
}
export async function deleteLabel(id) {
    const data = {
        labelId: id
    }
    return network.post('/label/deleteLabel', data)
        .then((response) => {
            return response
        })
}