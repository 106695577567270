<template>
  <div
    id="delete"
    class="card mt-4">
    <div class="card-header">
      <h5>{{ $tc('setting.delete_account.title') }}</h5>
      <p class="text-sm mb-0">
        {{ $tc('setting.delete_account.subtitle') }}
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <div class="form-check form-switch mb-0">
            <input
              id="flexSwitchCheckDefault0"
              v-model="confirm"
              class="form-check-input"
              type="checkbox">
          </div>
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">{{ $tc('setting.delete_account.confirm') }}</span>
          <span class="text-xs d-block">{{ $tc('setting.delete_account.checkbox') }}</span>
        </div>
      </div>
      <button
        class="btn bg-gradient-danger mb-0 ms-auto"
        type="button"
        name="button"
        :disabled="!confirm">
        {{ $tc('setting.delete_account.save') }}
      </button>
    </div>
  </div>
</template>
  
<script>

export default {
    name: 'DeleteAccount',
    data() {
        return {
            confirm: false
        }
    }
}
</script>