<template>
  <router-link
    :to="routeObject"
    class="nav-link"
    :class="classList + (active ? 'active' : '')"
    exact>
    <div class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
      <i :class="icon" />
    </div>
    <span class="link-name">{{ name }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'SidebarNavLink',
  props: {
    name: {
      type: String,
      default: ''
    },
    routeName: {
      type: String,
      default: ''
    },
    routeParams: {
      type: Object,
      default: () => {
      }
    },
    icon: {
      type: String,
      default: ''
    },
    classList: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    routeObject() {
      return { name: this.routeName, params: this.routeParams }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.icon-shape > svg {
  color: #3A416F
}
.nav-link.active .icon-shape > svg {
  color: #FFFFFF;
}
</style>
