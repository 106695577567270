import Vue from 'vue'
import VueRouter from 'vue-router'
import FacebookAuthenticate from '../views/SocialConnect/FacebookAuthenticate.vue'
import GoogleAuthenticate from '@/views/Insider/Googleauth.vue'
import GoogleGeneration from '@/views/Insider/GoogleGeneration.vue'
import GoogleCallback from '@/views/Insider/GoogleCallback.vue'
import SocialList from "@/views/SocialConnect/SocialList.vue"
import PageSelection from "@/views/SocialConnect/PageSelection.vue";
import BrandSelection from "@/views/SocialConnect/BrandSelection.vue";
import CalendarOverview from "@/views/Calendar/CalendarOverview.vue";
import composeOverview from "@/views/ComposeFeed/composeOverview.vue";
import engagementOverview from "@/views/Engagement/engagementOverview.vue";
import engagementDuplicateOverview from "@/views/Engagement/engagementDuplicateOverview.vue";
import AnalyticsOverview from "@/views/Analytics/AnalyticsOverview.vue";
import AnalyticsOverviewDemo from "@/views/Analytics/AnalyticsOverviewDemo.vue";
import SettingOverview from "@/views/Settings/SettingOverview.vue";
import LoginView from "@/views/Settings/LoginView.vue";
import InstagramPage from "@/views/SocialConnect/InstagramPage.vue";
// @ts-ignore
import { authGuard } from "@/auth/authGuard";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // @ts-ignore
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: AnalyticsOverview,
      beforeEnter: authGuard
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: AnalyticsOverview,
      beforeEnter: authGuard
    },
    {
      path: '/analyticsDemo',
      name: 'analyticsDemo',
      component: AnalyticsOverviewDemo,
      beforeEnter: authGuard
    },
    {
      path: '/loginView',
      name: 'LoginView',
      component: LoginView
    },
    {
      path: '/Calendar',
      name: 'Calendar',
      component: CalendarOverview,
      beforeEnter: authGuard
    },
    {
      path: '/composePage',
      name: 'composePage',
      component: composeOverview,
      props: (route) => ({
        defaultPost: route.query.defaultPost
      }),
      beforeEnter: authGuard
    },
    {
      path: '/engagements',
      name: 'engagements',
      component: engagementOverview,
      beforeEnter: authGuard
    },
    {
      path: '/engagementsdemo',
      name: 'engagementDuplicate',
      component: engagementDuplicateOverview,
      beforeEnter: authGuard
    },
    {
      path: '/settings',
      name: 'Settings',
      component: SettingOverview,
      beforeEnter: authGuard
    },
    {
      path: '/socials',
      name: 'SocialList',
      component: SocialList,
      props: (route) => ({
        token: route.params.token,
        brand: route.params.brand,
        pageId: route.params.pageId
      }),
      beforeEnter: authGuard
    },
    {
      path: '/socials/pages',
      component: PageSelection,
      name: 'PageSelection',
      props: (route) => ({
        token: route.params.token,
        brand: route.params.brand,
      }),
      beforeEnter: authGuard
    },
    {
      path: '/socials/new',
      component: BrandSelection,
      name: 'BrandSelection',
      beforeEnter: authGuard
    },
    {
      path: '/socials/add-instagram',
      component: InstagramPage,
      name: 'InstagramPage',
      beforeEnter: authGuard
    },
    {
      path: '/facebook-Authenticate',
      name: 'FacebookAuthenticate',
      component: FacebookAuthenticate,
      props: (route) => ({
        brand: route.params.brand,
        refresh: route.params.refresh,
        pageId: route.params.pageId
      }),
      beforeEnter: authGuard
    },
    // temporary Ads AI
    {
      path: '/ads/ai/google-auth',
      name: 'GoogleAuthenticate',
      component: GoogleAuthenticate
    },
    {
      path: '/ads/ai/google-callback',
      name: 'GoogleCallback',
      component: GoogleCallback
    },
    {
      path: '/ads/ai/google-generation',
      name: 'GoogleGeneration',
      component: GoogleGeneration,
      props: (route) => ({
        reportId: route.query.reportId,
      }),
    },
  ]
})

export default router
