<template>
  <div>
    <multiselect
      v-model="selectedLabels"
      :options="tagOptionsWithAdd"
      :multiple="true"
      track-by="_id"
      label="title"
      select-label=""
      deselect-label=""
      :placeholder="$tc('compose.labels.select_placeholder')"
      :selected-label="$tc('compose.labels.selected_label')"
      :style="{ display: 'inline-block' }"
      @select="onSelect">
      <template
        slot="tag"
        slot-scope="{ option }">
        <span
          class="multiselect__tag"
          :style="{ backgroundColor: option.color, marginBottom: '0px', marginTop: '5px' }">
          <span>{{ option.title }}</span>
          <i
            tabindex="1"
            class="multiselect__tag-icon"
            @click="removeLabelFromSelection(option)" />
        </span>
      </template>
      <template
        slot="option"
        slot-scope="{ option }">
        <span
          class="multiselect__tag"
          :style="{ backgroundColor: option.color }">
          <span>{{ option.title }}</span>
        </span>
      </template>
    </multiselect>
    <div
      v-if="popNewTag"
      class="new-tag-div row m-0 p-2">
      <div class="col-8"> 
        <label class="form-label">{{ $tc('compose.labels.new_tag') }}</label>
        <b-input
          v-model="newLabelText"
          class="form-control col-8 name-input"
          type="text"
          :placeholder="$tc('compose.labels.new_tag') " />
      </div>
      <div class="col-4">
        <label class="form-label">{{ $tc('compose.labels.color') }}</label>
        <b-form-group>
          <div class="color-circles row">
            <div
              v-for="(color, index) in labelColors"
              :key="index"
              class="col-2 mb-1"
              :class="{ selected: selectedColor === color }"
              :style="{ borderColor: color, backgroundColor: selectedColor === color ? 'transparent' : color }"
              @click="selectColor(color)" />
          </div>
        </b-form-group>
      </div>
      <!-- <div class="form-group">
      </div> -->
      <div>
        <b-button
          type="button"
          class="btn success btn-new-tag float-end mt-2 px-2 py-1 mb-0"
          @click="addNewTag">
          {{ $tc('generic.save') }}
        </b-button>
        <b-button
          type="button"
          class="btn cancel btn-new-tag float-start mt-2 px-2 py-1 mb-0 mr-2"
          @click="cancelAddTag">
          {{ $tc('generic.cancel') }}
        </b-button>
      </div>
    </div>
    <!-- <b-button
      v-b-tooltip.hover
      :title="$tc('compose.labels.edit_labels_tooltip')"
      variant="link"
      class="text-dark pb-0 px-2 show-labels-modal"
      @click.stop="showModal">
      <i class="fas fa-edit edit-label-icon" />
    </b-button> -->
    <!-- Modal -->
  </div>
</template>

<script>
import {createLabel, getLabels, deleteLabel} from "@/apis/LabelApi";
import Multiselect from 'vue-multiselect'

import Vue from "vue";
export default {
  name: 'Labels',
  components: {
    Multiselect
  },
  data() {
    return {
      modalVisible: false,
      labels: [],
      selectedLabels: [],
      newLabelText: '',
      labelColors: ['#2D7DD2', '#FFC145', '#FF6B6C', '#1D1E18', '#00BD9D', '#340068'],
      selectedColor: '', // Default color
      popNewTag: false
    }
  },
  computed: {
    tagOptionsWithAdd() {
      // Combine existing tags with the "Add New Tag" option
      return [...this.labels, { title: 'Ajouter nouveau tag', color: '#00bd9d', label: 'addNewTag' }];
    }
  },
  watch: {
    selectedLabels: function (val) {
      this.$emit('update-labels', val)
    }
  },
  mounted() {
    getLabels().then(response => {
      this.labels = response.data.labels
    })
    .catch((error) => {
      console.log(error);
      Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
    });
  },
  methods: {
    addNewTag() {
      if (this.newLabelText.trim() !== '') {
        this.addOrUpdateLabel()

      }
    },
    onSelect(tag) {
      if (tag.label === 'addNewTag') {
        this.selectedLabels = this.selectedLabels.filter((item) => {
          return !item.label &&  item.label !== 'addNewTag'
        })
        this.popNewTag = true
        this.addNewTag()
      }
    },
    showModal() {
      this.modalVisible = true;
    },
    deleteLabel(index) {
      deleteLabel(this.labels[index]._id).then(response => {
        this.labels.splice(index, 1);

      })
      .catch((error) => {
        console.log(error);
        Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
      });
    },
    addOrUpdateLabel() {
      if (this.newLabelText && this.selectedColor) {
        createLabel(this.newLabelText, this.selectedColor).then(response => {
          this.labels.push(response.data.createdLabel)
          this.selectedLabels.push(response.data.createdLabel)
        })
        .catch((error) => {
          console.log(error);
          Vue.toasted.show(this.$tc('loadingErrors.servererror'), {type: 'error'})
        })
        .finally(() =>{
          this.newLabelText = ''
          this.selectedColor = ''
          this.popNewTag = false
        })
      }
    },
    cancelAddTag() {
      this.popNewTag = false
      this.newLabelText = ''
      this.selectedColor = ''
    },
    selectColor(color) {
      this.selectedColor = color;
    },
    saveLabels() {
      this.$emit('update-labels', this.labels);
      this.modalVisible = false;
    },
    removeLabelFromSelection(label) {
      const index = this.selectedLabels.findIndex(item => item.label === label.label);
      if (index !== -1) {
        this.selectedLabels.splice(index, 1);
      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect__placeholder {
  margin-top: 4px !important;
  margin-bottom: 5px !important;
}
.multiselect__option--highlight {
  background: transparent !important;
  outline: none;
  color: white !important;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: transparent !important;
}
.multiselect__tag {
  margin-bottom: 0px !important;
}
.multiselect__option {
  padding: 8px 12px !important;
}
.label-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}
.remove-button {
    cursor: pointer;
}
.label-info {
    display: flex;
    border-radius: 5px;
    width: 100%;
    align-items: center;
}

.label-info span {
    padding: 10px;
    color: white;
    width: calc( 100% - 5px);
    display: inline-block;
    border-radius: 5px;
    margin-right: 10px;
}

.add-button {
    font-size: 25px;
    margin-top: 10px;
    padding: 5px 12px;
    cursor: pointer;
    background: transparent;
    color: #344767;
}

.color-circles {
    display: flex;
    align-items: center;
    width: 100%;
}

.color-circles div {
    height: 24px;
    max-width: 20px;
    margin-right: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.color-circles div.selected {
    border: 2px solid #ffffff;
    height: 26px;
}

.show-labels-modal{
  padding: 10px 4px 0px 4px !important;
  svg {
    font-size: 24px;
  }
}
.new-tag-div {
  border: 1px solid #dee2e6;
  border-top: 0;
  padding: 1rem;
  position: absolute;
  z-index: 9;
  width: 100%;
    background: white;
  input {
    margin-right: 10px;
  }
  .name-input {
    max-height: 35px;
  }
  .btn-new-tag {
    margin-top: 10px;
    text-transform: capitalize;
    font-size: 14px;
    &.success {
      background: #00bd9d;
      color: white;
      border-color: #00bd9d;
    }
    &.cancel {
      background: #ff6b6c;
      color: white;
      border-color: #ff6b6c;
    }
  }
}
</style>
