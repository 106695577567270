<template>
  <section>
    <div class="page-header min-vh-100">
      <div class="container position-static">
        <div class="row position-static">
          <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
            <loading-icon
              v-if="loading"
              :size="'medium'"
              :position="'center'"
              class="ai-loader" />
            <div
              :class="loading ? 'card-loading' : ''"
              class="card card-plain">
              <div class="pb-0 text-start">
                <h4 class="font-weight-bolder">
                  {{ $t('login.cta') }}
                </h4>
                <p class="mb-0">
                  {{ $t('login.signInMessage') }}
                </p>
              </div>
              <div class="">
                <form role="form">
                  <div class="mb-3">
                    <input
                      v-model="email"
                      type="email"
                      class="form-control form-control-lg"
                      :placeholder="$t('login.email')"
                      aria-label="Email">
                  </div>
                  <div class="mb-3">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control form-control-lg"
                      :placeholder="$t('login.password')"
                      aria-label="Password">
                  </div>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="rememberMe">
                    <label class="form-check-label" for="rememberMe">{{ $t('login.rememberMe') }}</label>
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
                      @click="signin">
                      {{ $t('login.cta') }}
                    </button>
                  </div>
                </form>
              </div>
              <div class="card-footer text-center pt-0 px-lg-2 px-1">
                <p class="mb-4 text-sm mx-auto">
                  {{ $t('login.signInText1') }}
                  <a target="_blank" href="https://monfeed.com/?page_id=312" class="text-primary text-gradient font-weight-bold">{{ $t('login.terms') }}</a>
                  {{ $t('login.and') }}
                  <a target="_blank" href="https://monfeed.com/?page_id=284" class="text-primary text-gradient font-weight-bold">{{ $t('login.privacy') }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
              <img src="https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/shapes/pattern-lines.svg" alt="pattern-lines" class="position-absolute opacity-4 start-0">
              <div class="position-relative">
                <img class="max-width-500 w-100 position-relative z-index-2" src="https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/illustrations/chat.png" alt="chat-img">
              </div>
              <h4 class="mt-5 text-white font-weight-bolder">"Attention is the new currency"</h4>
              <p class="text-white">The more effortless the writing looks, the more effort the writer actually put into the process.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoadingIcon from "@/views/Components/loadingIcon.vue";
import Vue from "vue"

export default {
  name: 'LoginView',
  components: {LoadingIcon},
  data() {
    return {
      loading: true,
      email: '',
      password: ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  },
  methods: {
    signin() {
      Vue.toasted.show(this.email + '' + this.password, { type: 'error' });
    }
  }
}
</script>
