export default {
  menu: {
    dashboard: 'Dashboard',
    compose: 'New Post',
    calendar: 'Calendar',
    posts: 'Posts',
    engagements: 'Engagement',
    socials: 'Social Accounts',
    settings: 'Settings',
  },
  login: {
    signInMessage: 'Sign in to your account',
    cta: 'Sign In',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember Me',
    signInText1: 'By signing in, you agree to',
    terms: 'our terms of use',
    and: 'and our',
    privacy: 'privacy policy.'
  },
  analytics: {
    reach: 'Reach',
    commentdelay: 'Response Delay',
    impressions: 'Impressions',
    posts: 'Posts',
    engagement_rate: 'Engagement Rate',
    new_followers: 'New Followers',
    comments: 'Comments',
    best_posts: {
      title: 'Best Posts',
      engagement_rate: 'Engagement Rate',
      reach: 'Reach',
      impressions: 'Impressions',
      caption: 'Caption',
      best_day: 'Best Day',
      best_etiquette: 'Best Tag',
      best_type_of_post: 'Best Type'
    },
    comments_card: {
      title: 'Sentiment Analysis of Comments',
      negative: 'Negative Comments',
      neutral: 'Neutral Comments',
      positive: 'Positive Comments',
      tooltip: 'This is an approximate sentiment analysis; AI is always learning and the analysis will improve over time.'
    },
    more_than: 'more than',
    less_than: 'less than'
  },
  setting: {
    password: {
      wrongPassword: 'Incorrect Password',
      success: 'Password Successfully Updated',
      changePassword: 'Change Password',
      oldPassword: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm New Password',
      updatePassword: 'Update Password',
      errorPasswordLength: 'Password must contain at least 8 characters',
      errorLowercaseLength: 'Password must contain at least 1 uppercase letter',
      errorUppercaseLength: 'Password must contain at least 1 lowercase letter',
      errorSpecialCharacter: 'Password must contain at least 1 special character',
      errorContainNumber: 'Password must contain at least 1 number',
      errorMustBeSame: 'Passwords must match',
    },
    delete_account: {
      confirm: 'Confirm',
      checkbox: 'I confirm that I want to delete my account',
      title: 'Delete Account',
      subtitle: 'Are you sure you want to delete your account?',
      save: 'Delete Account',
    },
  },
  compose: {
    thumbnail_title: 'Select a thumbnail',
    schedule_title: 'Schedule Post',
    title: 'Create a Post',
    subtitle: 'Create an effective post to get your message across.',
    textarea_placeholder: 'What would you like to share?',
    tooltip_upload: 'Add an image',
    tooltip_emoji: 'Add an emoji',
    tooltip_hashtag: 'Generate a hashtag with AI',
    tooltip_template: 'Choose a template',
    rewriteBtn : 'Generate with AI',
    schedule_publish: 'Schedule Post',
    instant_publish: 'Publish Now',
    time_to_post: 'Best times to post',
    time_selection: 'Choose the time to post',
    preview_title: 'Post Previews',
    preview_subtitle: 'Preview on social networks',
    preview_missing: 'No preview available',
    scheduled_for: {
      scheduled: 'Scheduled for',
      finalized: 'Scheduled for',
      published: 'Published on',
    },
    image_upload: {
      title: 'Add an image',
      upload_msg: 'Click or Drag and Drop an image',
      error_format: 'Unsupported format',
      search: 'Search for an image',
      error_max: 'Only one file maximum',
      from_unsplash: 'From Unsplash',
      by: 'by',
      use_image: 'Use',
      load_more: 'Load more',
      upload: 'Upload',
      stock: 'Free Photos',
      crop: 'Crop',
      crop_title: 'Crop the image',
    },
    labels : {
      label: 'Tags',
      title: 'Add tags',
      cancel: 'Back',
      create: 'Create',
      update: 'Update',
      placeholder: 'Tag title',
      select_placeholder: 'Select tags',
      selected_label: 'Selected',
      edit_labels_tooltip: 'Edit tags',
      new_tag: 'New tag',
      color: 'Color',
    },
    publish_type: {
      IMAGE: 'Image',
      VIDEO: 'Video',
      CAROUSEL: 'Carousel',
      TEXT: 'Text'
    },
    errors: {
      comment_empty: 'You must add a response',
      too_short: 'The text is a bit too short. Try with a longer text so we can make a more accurate rephrasing.',
      ratio_title : 'Instagram Stories use 9:16 media',
      ratio_text: 'So if you use a different aspect ratio, your media could be cut or resized. You can resize your images by selecting our edit functionality above. This functionality is not available for videos.',
      ig_image_required: 'You must upload at least one image to post on Instagram',
      channel_required: 'You must select at least one account to post on',
      caption_required: 'Caption is required',
      video_once_error: 'A Post can only contain one video. If you wish to upload this video, please first remove all media.',
      media_2207032: 'Failed to create a media container. Try again',
      media_2207053: 'An unknown error occurred during upload. Generate a new container and use it to try again. This only concerns video uploads.',
      media_2207027: 'Check the container status and publish when its status is "COMPLETED".',
      media_2207026: 'Unsupported video format. Advise the app user to upload a MOV or MP4 (MPEG-4 Part 14) file. Please see the video specifications.',
      media_2207008: 'Temporary error while publishing a container. Try again 1 to 2 times in the next 30 seconds to 2 minutes. If it fails, generate a new container ID and use it to try again.',
      media_2207006: 'Possible permission error due to missing permission or expired token. Generate a new container and use it to try again.',
      media_2207042: 'The app user has reached their daily publishing limit. Advise the app user to try again tomorrow.',
      media_2207051: 'The publishing action is suspected to be spam. We restrict certain activities to protect our community. Let us know if you can determine that the publishing actions are not spam.',
      media_2207057: 'The thumbnail offset you entered is out of bounds of the video duration. Add the correct offset in milliseconds.',
      media_2207003: 'Media upload is taking too long. Try again.',
      media_2207020: 'The media you are trying to access has expired. Please try again by generating a new container ID.',
      media_2207001: 'Instagram server error. Try again.',
      media_2207023: 'The media type {media-type} is unknown.',
      media_2207028: 'Your post cannot be used as a carousel. Carousels must have at least 2 photos/videos and a maximum of 10 photos/videos.',
      media_2207035: 'Product tag positions must not be specified for video media.',
      media_2207036: 'Product tag positions are required for photo media.',
      media_2207037: 'We could not add all the product tags. The product ID may be incorrect, the product may be deleted, or you may not have permission to tag the product.',
      media_2207040: 'You cannot use more than {max-tag-count} tags per created media.',
      media_2207050: 'The Instagram account is restricted.',
      media_2207052: 'Unable to retrieve media from this URI: {uri}.',
      media_2207004: 'The image is too large to be uploaded. It must be less than 5MB.',
      media_2207005: 'The image format is not supported. Supported formats are: {format}.',
      media_2207009: 'The submitted image with an aspect ratio of submitted-ratio cannot be published. Please submit an image with a valid aspect ratio.',
      media_2207010: 'The caption of the submitted image has submitted-caption-length characters. The maximum number of characters allowed for a caption is maximum-caption-length. Please submit media with a shorter caption.',
      schedule_required: 'You must select a publishing date',
      schedule_past: 'You cannot schedule a post in the past',
    }
  },
  comment: {
    title: 'Posts requiring a response',
    textarea_placeholder: 'Your response',
    tone: {
      select: 'Select a tone',
      casual: 'Casual Tone',
      formal: 'Formal Tone',
      friendly: 'Friendly Tone',
      exclusive: 'Exclusive Tone'
    },
    reformulate: 'Rephrase',
    mark_as_answered: 'Mark as answered',
    reformulate_error: 'Unable to rephrase the comment, please try again later',
    template : {
      title: 'Add templates',
      cancel: 'Back',
      create: 'Create',
      use: 'Use the template',
      placeholder_title: 'Title',
      placeholder_message: 'Message',
    },
  },
  calendar: {
    status: {
      draft: 'Draft',
      scheduled: 'Scheduled',
      imported: 'Imported',
      published: 'Published',
      finalized: 'Finalized'
    },
    week: 'Week',
    month: 'Month',
    update: 'Update Post',
    delete: 'Delete Post',
  },
  connectSocial: {
    brandSelection: {
      title: 'Add an Account',
      subtitle: 'Add a social account to start posting.',
      facebook: 'Connect a new Facebook Page',
      instagram: 'Connect a new Instagram Business Profile',
      instagramSubtitle1: 'Your Instagram acount needs to be connected with a <span style="color:#295396;font-weight: bold">Facebook Page.</span>',
      instagramSubtitle2: 'Sign in with Facebook below, and add the page that is connected to your instagram account.',
      instagramSubtitle3: 'Learn how to connect an Instagram account with a Facebook Page',
    },
    socialAccounts: {
      title: 'Social Accounts',
      subtitle: 'Overview of all social networks managed by Monfeed.com',
      new: 'Add an Account',
      added: 'Added on'
    },
    accountSelection: {
      title: 'Select an Account',
      subtitle: 'Select an account to connect',
    },
    actions: {
      remove: 'Remove Account',
      refresh: 'Refresh Account',
    }
  },
  previewPost: {
    xHoursAgo: '{hours} hours ago',
  },
  confirmationModal: {
    postDeletion: 'Are you sure you want to delete this post?',
    imageDeletion: 'Are you sure you want to delete this image?',
    socialDeletion: 'Are you sure you want to delete this account?',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  loadingErrors: {
    analyticsError: 'Some statistics could not be retrieved, try again later',
    no_social_providers: 'Add your first social account to use Monfeed.com',
    servererror: 'Something went wrong please try again later',
    no_social_provider_found: 'Account not found please try again',
    social_provider_existent: 'Account already connected',
    social_provider_existent_other: 'Account already connected to another user',
    no_page_found_for_refresh: 'No account found for refresh, please delete and try connect again',
    no_instagram_found: 'No Business Instagram account linked to this page',
    video_format: 'Video format not supported, only mp4 and mov are supported',
    best_hours_error: 'Unable to retrieve the best hours to post, please try again later',
  },
  savingSuccess: {
    social_provider_connected: 'Account successfully synced',
    social_provider_deleted: 'Account successfully deleted',
    post_published: 'Successfully published post.',
    post_scheduled: 'Successfully scheduled post.'
  },
  generic: {
    save: 'Save',
    cancel: 'Cancel',
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December'
    },
  }
}
