import axios from 'axios';
import network from './Network'

export async function getHoursToPost(socialProviderId, since, until) {
    let data = JSON.stringify({
      socialProviderId: socialProviderId,
      since: since,
      until: until
    })
    return network.post('/analytics/getHoursToPost', data)
        .then((response) => {
          return response
        })
  }
  
  export async function getSocialAnalytics(socialProviderId, since, until) {
    let data = JSON.stringify({
      socialProviderId: socialProviderId,
      since: since,
      until: until
    })
    return network.post('/analytics/socialAnalytics', data)
        .then((response) => {
          return response
        })
  }
  
  export async function getBestOf(socialProviderId, since, until) {
    let data = JSON.stringify({
      socialProviderId: socialProviderId,
      since: since,
      until: until
    })
    return network.post('/analytics/bestOf', data)
        .then((response) => {
          return response
        })
  }
  
  export async function getDbAnalytics(socialProviderId, since, until) {
    let data = JSON.stringify({
      socialProviderId: socialProviderId,
      since: since,
      until: until
    })
    return network.post('/analytics/dbAnalytics', data)
        .then((response) => {
          return response
        })
  }