<template>
  <div>
    <loading-icon
      :size="'medium'"
      :position="'center'" />
    <button
      class="button d-none"
      @click="facebookLogin">
      Login with Facebook
    </button>
  </div>
</template>
<script>
import LoadingIcon from "@/views/Components/loadingIcon.vue";

export default {
  name:"FacebookView",
  components: { LoadingIcon },
  props: {
    brand: {
      type: String,
      default: null
    },
    refresh: {
      type: String,
      default: null
    },
    pageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      config_id: ''
    };
  },
  mounted() {
    if (import.meta.env.VITE_MODE === 'facebook') {
      this.config_id = '132218806614728'
    } else {
      this.config_id = '1442786133172769'
    }
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
    //set a time out of 3s
    setTimeout(() => {
      this.facebookLogin()
    }, 2000)
  },
  methods: {
    facebookLogin() {
      let current = this
      window.FB.login(
        function(response) {
          if (response.status === "connected") {
            if (!current.refresh) {
              current.$router.push({ name: 'PageSelection', params: { token: response.authResponse.accessToken, brand: current.brand } })
            } else {
              current.$router.push({ name: 'SocialList', params: { token: response.authResponse.accessToken, pageId: current.pageId, brand: current.brand } })
            }
          } else {
            current.$router.push({ name: 'SocialList'})
          }
        },
        {
          config_id: this.config_id
        }
      );
    },

    async initFacebook() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: "824614949017372", //You will need to change this
                cookie: true,
                xfbml: true,
                version: "v13.0"
            });
            window.FB.AppEvents.logPageView();
        };
    },
    async loadFacebookSDK(d, s, id) {
        let js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }
  }
};
</script>
<style>
.button{
    color:white;
    min-width: 150px;
    background-color: #000000a1;
    height: 2.5rem;
    border-radius: 2rem;
    font-weight: 400;
    font-size: 0.8rem;
}

</style>